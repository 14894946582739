import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import AuthService from "../../../services/AuthService";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import PlaceHolder from "../../templates/PlaceHolder";
import { Button } from "@mui/material";
const usr = AuthService.getCurrentUser();

const Dashboard = () => {
  let navigate = useNavigate();
  let params: any = useParams();
  const { decodeHtml } = processHtml;
  const [dashboard, setDashboard] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState<any>(false);
  const [seconds, setSeconds] = React.useState<any>(null);
  const [dashboard_fetched, setDashboardFetched] = React.useState<any>(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    fetchDashboard();
  }, [params]);
  const fetchDashboard = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.fetchDashboard()
      .then(
        (result) => {
          console.log("dashboard :: |", result);
          setDashboardFetched(true);
          if (result) {
            setDashboard(result);
            const secs =
              new Date(result.progress_data?.ends).getTime() -
              new Date().getTime();
            /*   const seconds =
              result.progress_data.sales_end_in -
              result.progress_data.sales_start_in; */
            setSeconds(Math.ceil(secs));
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax
  let progressBar = (done: number, total: number) => {
    console.log(done, total);
    const one_perc = total / 100;
    const done_perc = done / one_perc;
    console.log(done_perc);
    return { done: done_perc };
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="z-high py10">
          {loading && <PlaceHolder type="dash" />}
          {loaded && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <a className="grid-card g7">
                    <div className="pxy20">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(dashboard?.contribution_card),
                        }}
                      ></div>
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <a className="grid-card bga">
                    <div className="pxy20">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(dashboard?.buy_card),
                        }}
                      ></div>
                    </div>
                  </a>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bga" to="/account/affiliate">
                    <div className="flex flex-row align-items-center pxy20">
                      <div className="spacer">
                        <div className="icon-pane mb10">
                          <i className="fa-users fas fa"></i>
                        </div>
                        <div className="txt-xsm">-</div>
                        <div className="pane-title">
                          <span>Affiliate</span>
                          <sup>
                            <i className="fa-check-circle color-approved fas fa"></i>
                          </sup>
                        </div>
                      </div>

                      <div className="spacer">
                        <div className="flex flex-row">
                          <div className="flex flex-col text-center">
                            <span className="txt-xsm">Earnings</span>
                            <h3 className="boldest">
                              <sup>$</sup>
                              {dashboard?.total_earnings}{" "}
                            </h3>
                          </div>
                          <span className="px10"></span>
                          <div className="flex flex-col text-center">
                            <span className="txt-xsm">Bal</span>
                            <h3 className="boldest">
                              <sup>$</sup>
                              {dashboard?.wallet_balance}
                            </h3>
                          </div>
                        </div>

                        <div className="flex flex-row justify-end pt10">
                          <div className="flex flex-col align-items-center pr20">
                            <span className="txt-xsm">Codes</span>
                            <h3 className="boldest">
                              {dashboard?.total_codes}
                            </h3>
                          </div>
                          <div className="flex align-items-center flex-col">
                            <span className="txt-xsm">Active</span>
                            <h3 className="boldest">
                              {dashboard?.active_codes}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Grid>
              </Grid>

              <div className="py10"></div>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <a className="grid-card bga">
                    <div className="pxy20">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(dashboard?.welcome_card),
                        }}
                      ></div>
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <a className="grid-card bga">
                    <div className="pxy20">
                      <div className="flex flex-row align-items-center">
                        <div className="spacer">
                          <div className="icon-pane mb10">
                            <i className="fa-stopwatch fas fa"></i>
                          </div>
                          <div className="txt-xsm">
                            {dashboard?.stage?.name}
                          </div>
                        </div>

                        <span className="px10"></span>

                        <div className="flex flex-col text-center">
                          <span className="txt-xsm">Token Sale Ends</span>
                          <h3 className="bold">COUNTDOWN</h3>
                        </div>
                      </div>
                      <div className="prog-container ">
                        <div className="prog-desc pxy10">
                          <div className="done-text">
                            <h4 className="txt-sm">Raised Amount</h4>
                            <span className="txt-sm">
                              {dashboard?.progress_data?.sale_stat?.raised}
                              {dashboard?.progress_data?.sale_stat?.currency}
                            </span>
                          </div>
                          <div className="spacer"></div>
                          <div className="total-text text-right">
                            <h4 className="txt-sm">Total Tokens</h4>
                            <span className="txt-sm">
                              {dashboard?.progress_data?.sale_stat?.total}
                              {dashboard?.progress_data?.sale_stat?.currency}
                            </span>
                          </div>
                        </div>
                        <div className="prog-signal">
                          <div className="status-bar">
                            <span
                              className="done-bar"
                              style={{
                                width: `${
                                  progressBar(
                                    dashboard?.progress_data?.sale_stat?.raised,
                                    dashboard?.progress_data?.sale_stat?.total
                                  ).done
                                }%`,
                              }}
                            >
                              {
                                progressBar(
                                  dashboard?.progress_data?.sale_stat?.raised,
                                  dashboard?.progress_data?.sale_stat?.total
                                ).done
                              }
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
