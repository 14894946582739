import React, { createContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as processHtml from "../../../services/processHtml";
import Edit from "@mui/icons-material/Edit";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import Delete from "@mui/icons-material/Delete";

import Slide from "@mui/material/Slide";
import { HomeContext } from "../../../services/HomeContext";
import { Breadcrumbs, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CloudUpload } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MissionCards() {
  let navigate = useNavigate();
  const { decodeHtml, truncateWord } = processHtml;
  const [home, setHome] = React.useContext(HomeContext);
  const [all_loaded, setAllLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [header, setHeader] = React.useState<any>("");
  const [name, setName] = React.useState<any>("");
  const [cards, setCards] = React.useState<any[]>([]);
  const [row, setRow] = React.useState<any[]>([]);
  const [message, setMessage] = React.useState<any>("");
  const [description, setDescription] = React.useState<any>("");
  React.useEffect(() => {
    console.log(home, "::home");
    //setHeader(home?.mission_card?.header);
    //setDescription(home?.mission_card?.message);
    //setName(home?.mission_card?.name);
    if (home?.mission_card) {
      let ars = [...home.mission_card];
      for (let i = 0; i < ars.length; i++) {
        ars[i]["preview_image"] =
          process.env.REACT_APP_PUBLIC_DOMAIN + ars[i]["picture"];
      }
      setCards(ars);
      setRow(ars);
    }
  }, [home]);

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit homepage",
  });

  const handleSubmit = (index: number) => {
    let formData = new FormData();
    formData.append("index", JSON.stringify(index));
    formData.append("banner", cards[index].form_data);
    formData.append("data", JSON.stringify(cards[index]));
    formData.append("mode", "mission_card");
    /*   const data = {
      data: JSON.stringify(cards),
      mode: "mission_card",
    }; */
    console.log(formData, "::Data");
    setLoading(true);
    setLoaded(false);
    HttpService.editHomepage(/* data */ formData)
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({ ...modal, onopen: true, message: resp.message });
          }
          setTimeout(() => {
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({ ...modal, onopen: true, message: "Error" });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const [field, setField] = React.useState<any>("");
  const [field_value, setValue] = React.useState<any>("");
  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };

  const handleInput = (event: any, index: number, label: string) => {
    const targ = event.target;
    const name = targ.name;
    const value = targ.value;
    console.log(name, value, index, label, ":::");
    let cds = [...cards];
    cds[index] = { ...cds[index], [label]: value };
    setCards([...cds]);
  };

  const handleFileChange = (e: any, index: number) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    console.log(fname, "::name");
    formData.append("file", flx);
    console.log(flx);
    let cds = [...cards];
    cds[index] = {
      ...cds[index],
      preview_image: URL.createObjectURL(flx),
      form_data: flx,
      new_file: true,
    };

    setCards([...cds]);
    //setPreview(URL.createObjectURL(flx)); // Would see a path?
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/homepage">Edit Homepage</Link>
                  </Breadcrumbs>
                  <h2>Mission Cards</h2>
                </div>
              </div>
            </div>

            <section className="pxy20">
              {home && (
                <Grid container spacing={2}>
                  {cards.map((item: any, index: number) => (
                    <Grid item xs={12} sm={6} md={4} key={item.sub + index}>
                      <div className="card-container">
                        <div className="card-top-image">
                          <img src={item.preview_image} alt="" />
                          <div className="conto">
                            <div className="profiler">
                              <div className="chano bg-white flex align-items-center justify-content-center">
                                <span className="att">
                                  <Button component="label" variant="contained">
                                    <CloudUpload />
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(e) =>
                                        handleFileChange(e, index)
                                      }
                                    />
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={loading ? " input iconed " : " input "}>
                          <label>sub Header</label>
                          <input
                            type="text"
                            className="input-form-control md-input"
                            name="sub[]"
                            disabled={loading}
                            value={cards[index].sub}
                            onChange={(e) => handleInput(e, index, "sub")}
                          />
                        </div>

                        <div className={loading ? " input iconed " : " input "}>
                          <label>Header</label>
                          <input
                            type="text"
                            disabled={loading}
                            className="input-form-control md-input"
                            name="header[]"
                            value={cards[index].header}
                            onChange={(e) => handleInput(e, index, "header")}
                          />
                        </div>

                        <textarea
                          disabled={loading}
                          name="text[]"
                          className="input-form-control sm-input mb10"
                          placeholder="short message"
                          value={cards[index].text}
                          onChange={(e) => handleInput(e, index, "text")}
                        ></textarea>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          disabled={loading}
                          onClick={() => handleSubmit(index)}
                        >
                          {loading ? "Working..." : " Update "}
                        </Button>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </section>
          </Card>
        </div>
      </section>

      <CustomModal data={modal} />
    </React.Fragment>
  );
}
