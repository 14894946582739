import React from "react";

import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import SeoModule from "../../../services/SeoModule";
import HomeMain from "./HomeMain";
import Faq from "./Faq";
import LeaveMessage from "./LeaveMessage";
import Cards from "./Cards";
import Thinking from "./Thinking";
import Profiles from "./Profiles";
import PgIntro from "./PgIntro";
import HomeNews from "./HomeNews";
import "./Home.css";
import HttpService from "../../../services/HttpService";
import { HomeProvider } from "../../../services/HomeContext";

const Home = () => {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomeProvider>
      <SeoModule
        title="Urum - Home"
        description="Fintech: banking, crypto, investments"
        name="Urum"
        page_url={process.env.REACT_APP_PUBLIC_DOMAIN}
        page_image={process.env.REACT_APP_PUBLIC_DOMAIN + "images/logo.png"}
        type="Event & Info"
      />
      <div className="main-bg-grad home-cover">
        <div className="home-overlay"></div>
        <Header />
        <HomeMain />
      </div>
      {/*  <Cards /> */}
      <Thinking />
      <PgIntro />
      {/*  <Profiles /> */}
      <LeaveMessage />
      {/*<HomeNews />
        <Faq /> */}
      <Footer />
    </HomeProvider>
  );
};

export default Home;
