import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import Collapse from "@mui/material/Collapse";
import Settings from "@mui/icons-material/Settings";
import Dashboard from "@mui/icons-material/Dashboard";
import "./Header.css";
import DesktopHeaderNav from "./DesktopHeaderNav";
import MobileHeaderNav from "./MobileHeaderNav";
const Header = () => {
  const [isTogged, setIsTogged] = React.useState(false);
  const page = useLocation().pathname;
  const logx = AuthService.isLogged();
  const usr = AuthService.getCurrentUser();
  const [isLogged, setIsLogged] = React.useState(logx);

  const closeNav = () => {
    setNavData({ ...nav_data, onopen: false });
  };
  const [nav_data, setNavData] = React.useState<any>({
    ononpen: false,
    onclose: closeNav,
    isLogged: isLogged,
  });
  const navigate = useNavigate();

  const doLogout = () => {
    AuthService.logout().then(() => {
      console.log("Session Cleared...");
      setIsLogged(false);
      window.location.href = "/";
      return;
    });
  };

  const toggleNav = () => {
    setNavData({
      ...nav_data,
      isLogged: isLogged,
      onopen: true,
      onclose: closeNav,
    });
    setIsTogged((cur) => !cur);
  };

  const doBoth = () => {
    doLogout();
    toggleNav();
  };

  const showMobileNav: boolean = isTogged ? true : false;
  const pages: any[] = [
    { path: "/account/dashboard", component: "Dashboard", icon: <Dashboard /> },
    {
      path: "/account/settings",
      component: "Account Settings",
      icon: <Settings />,
    },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [search, setSearch] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [properties, setProperties] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (loaded) {
      setProperties([]);
    }
  }, []);

  const redir = (link: string) => {
    return navigate(`/${link}`);
  };
  return (
    <React.Fragment>
      <div className="main-header spacer">
        <div className="container">
          <div className="header-items">
            <div className="header-logo">
              <a href="/">
                <img src="images/logo.png" alt="" />
              </a>
            </div>
            <span className="spacer"></span>
            <DesktopHeaderNav isLogged={isLogged} />
            <span className="ham-nav">
              <a onClick={() => setIsTogged(!isTogged)}>
                <i className={`fas ${isTogged ? "fa-close" : "fa-bars"}`}></i>
              </a>
            </span>
          </div>
          <div className="mobile-header-bar">
            <Collapse in={isTogged}>
              <MobileHeaderNav
                isLogged={isLogged}
                setIsTogged={setIsTogged}
                isTogged={isTogged}
              />
            </Collapse>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
