import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";

const PlaceHolder = (props?: any) => {
  const { type, max_grid, card_height, card_border_radius, card_width } = props;
  return (
    <React.Fragment>
      {type === "block_list" && (
        <Grid container spacing={2.5}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any, index: number) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={item}>
              <Skeleton
                sx={{
                  borderRadius: card_border_radius
                    ? card_border_radius + "px"
                    : "1px",
                }}
                variant="rectangular"
                width={"100%"}
                height={card_height ? card_height : 350}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {type === "sentence" && (
        <Skeleton
          variant="rectangular"
          width={card_width ? card_width + "%" : "100%"}
          height={20}
        />
      )}
      {type === "edit_page" && (
        <div className="pxy20">
          {" "}
          <Skeleton
            sx={{ borderRadius: "6px", marginBottom: "15px" }}
            variant="rectangular"
            width={"100%"}
            height={48}
          />
          <Skeleton
            sx={{ borderRadius: "6px", marginBottom: "15px" }}
            variant="rectangular"
            width={"100%"}
            height={230}
          />
          <Skeleton
            sx={{ borderRadius: "6px", marginBottom: "10px" }}
            variant="rectangular"
            width={80}
            height={40}
          />
        </div>
      )}
      {type === "dash" && (
        <>
          <Grid container spacing={2}>
            {[1, 2, 3].map((item: number, index: number) => (
              <Grid item xs={12} sm={6} md={4}>
                <Skeleton
                  sx={{ borderRadius: "15px", marginBottom: "10px" }}
                  variant="rectangular"
                  width={"100%"}
                  height={160}
                />
              </Grid>
            ))}
          </Grid>
          <div className="py10"></div>
          <Grid container spacing={2}>
            {[1, 2].map((item: number, index: number) => (
              <Grid item xs={12} sm={12} md={6}>
                <Skeleton
                  sx={{ borderRadius: "15px", marginBottom: "10px" }}
                  variant="rectangular"
                  width={"100%"}
                  height={200}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {type === "list" && (
        <Box
          sx={{
            display: "flex",
            marginBottom: "10px",
            marginTop: "5px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ flexGrow: 0 }}>
            <>
              <Skeleton variant="circular" width={40} height={40} />
            </>
          </Box>
          <Box sx={{ flexGrow: 1, marginLeft: "5px" }}>
            <>
              <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.8rem" }} />
            </>
          </Box>
          <Box sx={{ flexGrow: 0, marginLeft: "6px" }}>
            <>
              <Skeleton
                variant="text"
                sx={{ fontSize: "0.8rem", width: "40px" }}
              />
            </>
          </Box>
        </Box>
      )}

      {type === "gallery-content" && (
        <Box
          sx={{
            display: "flex",
            margin: "20px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            sx={{ borderRadius: "4px", marginBottom: "7px" }}
            variant="rectangular"
            width={"100%"}
            height={25}
          />
          <Skeleton
            sx={{ borderRadius: "4px", marginBottom: "15px" }}
            variant="rectangular"
            width={"100%"}
            height={14}
          />

          <Skeleton
            sx={{ borderRadius: "4px" }}
            variant="rectangular"
            width={"100%"}
            height={320}
          />
        </Box>
      )}

      {type === "profile" && (
        <Box
          sx={{
            display: "flex",
            marginBottom: "10px",
            padding: "30px",
            marginTop: "5px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ flexGrow: 0, marginRight: "20px" }}>
            <>
              <div style={{ marginBottom: "20px" }}>
                <Skeleton variant="circular" width={170} height={170} />
              </div>
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
            </>
          </Box>
          <Box sx={{ flexGrow: 1, paddingLeft: "35px" }}>
            <div style={{ padding: "20px" }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2.1rem", marginBottom: "10px" }}
              />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
              <div className="py30"></div>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2.1rem", marginBottom: "10px" }}
              />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1.1rem" }} />
            </div>
          </Box>
          <Box sx={{ flexGrow: 0, marginLeft: "6px" }}></Box>
        </Box>
      )}

      {type === "horizontal_list" && (
        <Box sx={{ padding: "5px" }}>
          {[1, 2, 3, 4, 5, 6].map((item: number, index: number) => (
            <Skeleton
              key={item}
              sx={{
                borderRadius: "1px",
                display: "block",
                borderBottom: "1px solid #ccc",
              }}
              variant="rectangular"
              height={60}
              width={"100%"}
            />
          ))}
        </Box>
      )}

      {type === "featured" && (
        <div>
          {[1, 2, 3, 4].map((a: number, i: number) => (
            <div className="mb5" key={a}>
              <Skeleton
                sx={{ borderRadius: "1px", display: "block" }}
                variant="rectangular"
                height={"90px"}
                width={"100%"}
              />
            </div>
          ))}
        </div>
      )}

      {type === "users" && (
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item: number, index: number) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={max_grid ? max_grid : 3}
              key={item}
            >
              <Skeleton
                sx={{ borderRadius: "1px", display: "block" }}
                variant="rectangular"
                height={"90px"}
                width={"100%"}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {type === "highlighted" && (
        <div
          className="flex flex-col"
          style={{ minHeight: "400px", zIndex: "9000000" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Skeleton variant="rectangular" width={"100%"} height={400} />
            </Grid>
          </Grid>
        </div>
      )}

      {type === "fullpage" && (
        <Grid
          container
          spacing={2.5}
          sx={{
            display: "flex",
            flexGrow: "1",
            alignContent: "stretch",
            marginTop: "20px",
          }}
        >
          <Grid item xs={12} sm={12} md={8} sx={{ m: "0", p: "0" }}>
            <Skeleton variant="rectangular" width={"100%"} height={400} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ m: "0", p: "0" }}>
            <Skeleton variant="rectangular" width={"100%"} height={400} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default React.memo(PlaceHolder);
