import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import PlaceHolder from "../../templates/PlaceHolder";

import CustomModal from "../../templates/CustomModal";

const ExecutivesEdit = () => {
  let navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const isParam = params.eId ? true : false;
  let [executives, setExecutives] = React.useState<any>({});
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit Executives",
  });

  const [name, setName] = React.useState<any>("");
  const [designation, setDesignation] = React.useState<any>("");
  const [bio, setBio] = React.useState<any>("");
  const [tenure, setTenure] = React.useState<any>("");
  let [id, setId] = React.useState<any>(false);
  let [is_current, setIsCurrent] = React.useState<any>(1);

  React.useEffect(() => {
    console.log(params);
    if (isParam) {
      getExecutives(params.eId);
    }
  }, [params]);

  const getExecutives = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.getExecutivesDetails(id)
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setExecutives(result.data);
            setName(result.data.name);
            setTenure(result.data.tenure);
            setBio(result.data.bio);
            setIsCurrent(result.data.is_current);
            setDesignation(result.data.designation);
            setId(result.data.id);
            setPreview(
              process.env.REACT_APP_PUBLIC_DOMAIN + result.data.picture
            );
          } else {
            setExecutives({});
          }
        },
        (error) => {
          setExecutives({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onHtmlChange = (e: any) => {
    setBio(e.target.value);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("is_current", is_current);
    formData.append("tenure", tenure);
    formData.append("id", id);
    formData.append("banner", file);
    formData.append("bio", bio);
    formData.append("is_new_file", new_file);
    HttpService.editExecutives(formData)
      .then((resp) => {
        console.log(resp);

        setModal({ ...modal, onopen: true, message: resp.message });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.taget);
      setBio(e.target.value);
    },
    [bio]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/executives">Executives</Link>
                    <span>{executives.name}</span>
                  </Breadcrumbs>
                  <h2>Edit Executives</h2>
                </div>
              </div>
            </div>

            {loading && <PlaceHolder type="edit_page" />}

            {loaded && (
              <div className="py30 px20">
                <div className={loading ? " input iconed " : " input "}>
                  <label>Name</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="name"
                    disabled={loading}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"Name "}
                  />
                </div>

                <div className={loading ? " input iconed " : " input "}>
                  <label>Position</label>
                  <select
                    className="input-form-control"
                    onChange={(e) => setDesignation(e.target.value)}
                    name="designation"
                    defaultValue={designation}
                  >
                    <option value="0">Select Designation</option>
                    <option value="President General">President General</option>
                    <option value="Vice President General">
                      Vice President General
                    </option>
                    <option value="Second Vice President General">
                      Second Vice President General
                    </option>
                    <option value="Secretary General">Secretary General</option>

                    <option value="Assistant Secretary General">
                      Assistant Secretary General
                    </option>
                    <option value="Treasurer">Treasurer</option>
                    <option value="Financial Secretary">
                      Financial Secretary
                    </option>
                    <option value="Provost">Provost</option>
                    <option value="Provost1">Provost1</option>
                    <option value="Provost2">Provost2</option>
                    <option value="PRO">PRO</option>
                    <option value="PRO1">PRO1</option>
                    <option value="PRO2">PRO2</option>
                    <option value="PRO3">PRO3</option>
                  </select>
                </div>

                <div className={loading ? " input iconed " : " input "}>
                  <label>Tenure Period</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="tenure"
                    disabled={loading}
                    value={tenure}
                    onChange={(e) => setTenure(e.target.value)}
                    placeholder={"Eg: 2020 - 2023 "}
                  />
                </div>

                <div className={loading ? " input iconed " : " input "}>
                  <label>Leadership Status</label>
                  <select
                    className="input-form-control"
                    onChange={(e) => setIsCurrent(e.target.value)}
                    name="is_current"
                    defaultValue={is_current}
                  >
                    <option value="1">Current Executive</option>
                    <option value="0">Past Leader</option>
                  </select>
                </div>
                <div className="banner-section">
                  {preview_image && (
                    <div className="image_preview">
                      <img
                        className=""
                        src={preview_image}
                        alt="preview Image"
                      />
                    </div>
                  )}
                  <div
                    className={
                      loading ? " input iconed mt20 pt10" : " input mt20 pt10"
                    }
                  >
                    <label>Attach Executive's Picture</label>
                    <input
                      type="file"
                      className="form-control"
                      name="file"
                      onChange={handleFileChange}
                      placeholder={"Executive's Picture "}
                    />
                  </div>
                </div>

                <div className="mb10">
                  <DefaultEditor
                    className="form-control"
                    value={bio}
                    disabled={loading}
                    placeholder="Executive's Bio"
                    onChange={onHtmlChange}
                  />
                </div>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? "Working..." : " Edit Executives "}
                </Button>
              </div>
            )}
          </Card>
        </div>
      </section>
      <CustomModal data={modal} />
    </React.Fragment>
  );
};

export default ExecutivesEdit;
