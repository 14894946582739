import React from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import GalleryCarousel from "../../templates/GalleryCarousel";
import DatePipe from "../../../pipes/DatePipe";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import ConfirmModal from "../../templates/ConfirmModal";
import PlaceHolder from "../../templates/PlaceHolder";
import { Breadcrumbs } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import PublishOutlined from "@mui/icons-material/PublishOutlined";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import { ToggleOff, ToggleOn } from "@mui/icons-material";
import { Console } from "console";

const GalleryDetails = (props: any) => {
  let navigate = useNavigate();
  let params: any = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [gallery, setGallery] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState<any>(false);
  const [publish_mode, setPublish] = React.useState("publish");
  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.galleryId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchgallery(params.galleryId);
    }
  }, [params.galleryId]);

  const fetchgallery = (id: any) => {
    console.log(id);
    setLoading(true);
    setLoaded(false);
    HttpService.getGalleryDetails(id)
      .then(
        (result) => {
          console.log("::|", result);
          if (result) {
            setGallery(result.data);
            const pub_mode =
              result.data.is_published === 1 ? "unpublish" : "publish";
            console.log("[o]", pub_mode);
            setPublish(pub_mode);
          } else {
            const now = new Date().getTime() / 1000;
            setGallery({
              title: "Gallery does not exist",
              content: `The gallery you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong gallery url.`,
              create_date: now,
            });
          }
        },
        (error) => {
          setGallery({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const launchEdit = () => {
    return navigate(`/admin/gallery/e/${gallery.id}`);
  };
  const launchPopulate = () => {
    return navigate(`/admin/gallery/px/${gallery.id}`);
  };
  const doPublish = (data: any) => {
    setLoading(true);
    setLoaded(false);
    setConfirm({
      ...confirm,
      onopen: true,
      id: data.id,
      title: `${data.action} gallery`,
      loading_text: `${data.action}ing...`,
    });
    HttpService.publishGallery(data)
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          setConfirm({
            ...confirm,
            onopen: true,
            id: data.id,
            loading_text: result.message,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  const closeConfirm = () => {
    setConfirm({ ...confirm, onopen: false });
  };
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onaccept: doPublish,
    onclose: closeConfirm,
    loading_text: "",
    title: "",
    id: "",
    action: "",
  });
  const launchAction = (data: any) => {
    setConfirm({
      ...confirm,
      onopen: true,
      id: data.id,
      action: data.action,
      title: data.action + " gallery",
      loading_text: `Are you sure you want to ${data.action} this gallery?`,
    });
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <section className="container">
          {loaded && (
            <>
              <div className="pb20">
                <Breadcrumbs>
                  <Link to={"/admin/dashboard"}>Dashboard</Link>
                  <Link to={"/admin/gallery"}>Gallery</Link>
                </Breadcrumbs>
                <h1>{gallery.caption}</h1>
                <div className="date-span">
                  <i className="fas fa-clock"></i>{" "}
                  <DatePipe value={gallery.create_date * 1000} />
                </div>
              </div>
              <div className="py10 flex flex-row">
                <span className="spacer"></span>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Tooltip
                    title="Add More Items"
                    sx={{ zIndex: "3000000000000000 !important" }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      onClick={launchPopulate}
                    >
                      <Add /> Add More
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="Edit Gallery"
                    sx={{ zIndex: "30000000000000000 !important" }}
                  >
                    <Button size="small" color="primary" onClick={launchEdit}>
                      <Edit /> Edit
                    </Button>
                  </Tooltip>

                  <Tooltip title={`${publish_mode} gallery`}>
                    {/*   <Button
                      size="small"
                      color="primary"
                      onClick={() =>
                        launchAction({ id: gallery.id, action: publish_mode })
                      }
                    >
                      <PublishOutlined /> {publish_mode}
                    </Button> */}

                    <Button
                      color={gallery.is_published === 1 ? "inherit" : "primary"}
                      size="medium"
                      onClick={() =>
                        launchAction({ id: gallery.id, action: publish_mode })
                      }
                    >
                      {gallery.is_published == "1" ? (
                        <ToggleOff />
                      ) : (
                        <ToggleOn />
                      )}
                    </Button>
                  </Tooltip>

                  <Tooltip title={`delete gallery`}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() =>
                        launchAction({ id: gallery.id, action: "delete" })
                      }
                    >
                      <DeleteForeverOutlined /> Delete
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </div>
              <Card>
                {loaded && (
                  <>
                    <div className="py">
                      <section>
                        <div className="gal-con">
                          {loaded && gallery && (
                            <GalleryCarousel items={gallery.photos} />
                          )}
                        </div>

                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={0}
                          sx={{
                            display: "flex",
                            flexGrow: "1",
                            alignContent: "stretch",
                            marginTop: "20px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            sx={{ m: "0", p: "0" }}
                          >
                            <div
                              className="px20 pb20"
                              dangerouslySetInnerHTML={{
                                __html: decodeHtml(gallery.description),
                              }}
                            ></div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            ==========-======
                          </Grid>
                        </Grid>
                      </section>
                    </div>
                  </>
                )}
              </Card>
            </>
          )}
        </section>
      </section>
      <ConfirmModal data={confirm} />
    </React.Fragment>
  );
};

export default GalleryDetails;
