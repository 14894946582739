import React from "react";
import { Routes, Route } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import GalleryDetail from "./GalleryDetail";
import GalleryList from "./GalleryList";

const Gallery = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<GalleryList />} />
        <Route path="/:id" element={<GalleryDetail />} />
      </Routes>
    </React.Fragment>
  );
};

export default Gallery;
