import * as React from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidePanel from "./AdminSidePanel";
import AdminHeader from "./AdminHeader";
import Dashboard from "./Dashboard/Dashboard";
import Four0Four from "../Public/Four0Four/Four0Four";
import AuthService from "../../services/AuthService";
import HttpService from "../../services/HttpService";
import Faq from "./Faq/Faq";
import "../dashboard.css";
import Login from "./Login/Login";
import Pages from "./Pages/Pages";
import Site from "./Site/Site";
import Homepage from "./Homepage/Homepage";
import Articles from "./Articles/Articles";
import Gallery from "./Gallery/Gallery";
import Executives from "./Executives/Executives";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Admin() {
  console.log("Admin page Renders");
  const [redirect, setRedirect] = React.useState(false);
  const [user_logged, setUserLogged] = React.useState(false);
  const [to_verify, setToVerify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [loading_text, setLoadingText] = React.useState(
    "Please enter the verification code sent your phone or email"
  );
  const [verify_data, setVerifyData] = React.useState<any>({});
  const usr = AuthService.getCurrentUser();
  let navigate = useNavigate();
  let params = useParams();
  React.useEffect(() => {
    HttpService.checkSession().then(
      (res) => {
        console.log("r::", res);
        if (!res.status || res.status === 0) {
          //AuthService.logout();
          setRedirect(true);
          //return navigate("/admin/login");
        } else {
          if (usr.role !== "admin") {
            //return navigate("/admin/login");
          }
          setUserLogged(true);
        }
      },
      (error) => {
        console.log(error.message);
        //AuthService.logout();
        //return navigate("/admin/login");
      }
    );
  }, []);
  React.useEffect(() => {
    console.log("PAS::", params["*"]);
    setPath(params["*"]);
  }, [params]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const doLogout = () => {
    AuthService.logout().then(() => {
      setTimeout(() => {
        console.log("Session Cleared...");
        window.location.href = "/";
        return;
      }, 1);
    });
  };

  const [path, setPath] = React.useState<any>("dashboard");

  const navas = [
    { path: "dashboard", title: "Dashboard" },
    { path: "faq", title: "FAQ" },
    { path: "pages", title: "Pages" },
    { path: "homepage", title: "Homepage" },
    { path: "site-settings", title: "Site Settings" },
  ];

  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  console.log("Page is::: ", page, "pgarr is: ", pgarr);
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: "1 1",
        width: "100%",
        minHeight: "100vh",
        padding: "0 !important",
        backgroundColor: "#f5f5f5",
      }}
    >
      <CssBaseline />
      {/*      {pgarr[2] !== "login" && user_logged && ( */}
      <>
        <AdminHeader
          open={open}
          toggleDrawer={toggleDrawer}
          DrawerHeader={DrawerHeader}
          doLogout={doLogout}
          usr={usr}
        />
        <AdminSidePanel
          onopen={open}
          onclose={handleDrawerClose}
          DrawerHeader={DrawerHeader}
          doLogout={doLogout}
        />
      </>
      {/*   )} */}
      <main style={{ width: "100%", height: "100%" }}>
        <DrawerHeader />

        <Routes>
          {/* {user_logged && ( */}
          <>
            <Route path="" element={<Dashboard />}></Route>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/faq//*" element={<Faq />}></Route>
            <Route path="/pages//*" element={<Pages />}></Route>
            <Route path="/executives//*" element={<Executives />}></Route>
            <Route path="/articles//*" element={<Articles />}></Route>
            <Route path="/gallery//*" element={<Gallery />}></Route>
            <Route path="/homepage//*" element={<Homepage />}></Route>
            <Route path="/site-settings//*" element={<Site />}></Route>
            <Route
              path="*"
              element={
                <>
                  <AdminHeader />
                  <Four0Four />
                </>
              }
            />
          </>
          {/* )} */}
          {/* <Route path="/login" element={<Login />}></Route> */}
        </Routes>
      </main>
    </Box>
  );
}
