import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Four0Four from "./Four0Four/Four0Four";
import OauthPage from "./Oauth/Oauth";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyRegistration from "./VerifyRegistration/VerifyRegistration";
import FAQ from "./FAQ/FAQ";
import Privacy from "./Privacy/Privacy";
import Terms from "./Terms/Terms";
import About from "./About/About";
import Gallery from "./Gallery/Gallery";
import Articles from "./Articles/Articles";
import Festivals from "./Festivals/Festivals";
import Villages from "./Villages/Villages";
import Executives from "./Executives/Executives";
import History from "./History/History";
import PastLeaders from "./PastLeaders/PastLeaders";
import "./Public.css";

const Public = () => {
  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  console.log("Page is::: ", page, "pgarr is: ", pgarr);

  return (
    <React.Fragment>
      {page !== "/" &&
        page !== "/login" &&
        page !== "/register" &&
        page !== "/whitepaper" &&
        pgarr[1] !== "whitepaper" &&
        page !== "/paper" &&
        page !== "/about" &&
        pgarr[1] !== "paper" &&
        pgarr[1] !== "reset-password" &&
        pgarr[1] !== "verify-registration"}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="" element={<Home />}></Route>
        <Route path="/executives//*" element={<Executives />}></Route>
        <Route path="/past-leaders//*" element={<PastLeaders />}></Route>
        <Route path="/history" element={<History />}></Route>
        <Route path="/gallery//*" element={<Gallery />}></Route>
        <Route path="/articles//*" element={<Articles />}></Route>
        <Route path="/festivals" element={<Festivals />}></Route>
        <Route path="/villages" element={<Villages />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route
          path="/reset-password/:id/:token"
          element={<ResetPassword />}
        ></Route>
        <Route
          path="/verify-registration/:id/:token"
          element={<VerifyRegistration />}
        ></Route>
        <Route path="/oauth-setup/:oauth" element={<OauthPage />}></Route>
        <Route path="/faq" element={<FAQ />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route
          path="*"
          element={
            <>
              <Four0Four />
            </>
          }
        />
      </Routes>
      {page !== "/" &&
        page !== "" &&
        page !== "/register" &&
        page !== "/whitepaper" &&
        pgarr[1] !== "whitepaper" &&
        page !== "/paper" &&
        pgarr[1] !== "paper" &&
        pgarr[1] !== "verify-registration" &&
        pgarr[1] !== "reset-password" &&
        page !== "/login" && <Footer />}
    </React.Fragment>
  );
};

export default Public;
