import axios from "axios";
import authHeader from "./AuthHeaders";

const API_URL = process.env.REACT_APP_API_DOMAIN;
const SERVER_API_URL = process.env.REACT_APP_API_DOMAIN;
const API_SECRET = process.env.REACT_APP_API_SECRET;
type headerType = {
  Authorization?: string;
  Bearer?: string;
};
class HttpService {
  auh: headerType = authHeader();
  listNews(options: any) {
    const query_string = new URLSearchParams(options);
    if (options.is_laravel) {
      const url =
        SERVER_API_URL + "list_news?secret=" + API_SECRET + "&" + query_string;

      console.log("listing news::: ", url, options, { headers: this.auh });
      return axios.post(url, options).then((response) => {
        console.log("::faqres:::", response);
        return response.data;
      });
    } else {
      const url = SERVER_API_URL + "custom/news";
      console.log("listing news::: ", url, options, { headers: this.auh });
      return axios
        .post(url, options, { headers: this.auh })
        .then((response) => {
          console.log("::newsres:::", response);
          return response.data;
        });
    }
  }

  getNewsDetails(url: string) {
    const eurl = SERVER_API_URL + "get_news_details?secret=" + API_SECRET;

    console.log("fetching faq details::: ", eurl, url, { headers: this.auh });
    return axios
      .post(eurl, { url: url }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  removeNews(id: any) {
    const url = SERVER_API_URL + "remove_news?secret=" + API_SECRET;
    console.log("removing news::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  publishNews(options: any) {
    const url = SERVER_API_URL + "publish_news?secret=" + API_SECRET;
    console.log("publishing news::: ", url, options, { headers: this.auh });
    return axios.post(url, options, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  listGallery(options: any) {
    const url = SERVER_API_URL + "list_gallery?secret=" + API_SECRET;
    console.log("listing gallery::: ", url, options, { headers: this.auh });
    return axios.post(url, options).then((response) => {
      console.log("::faqres:::", response);
      return response.data;
    });
  }

  getGalleryDetails(id: any) {
    const eurl = SERVER_API_URL + "get_gallery_details?secret=" + API_SECRET;
    const eurl2 =
      SERVER_API_URL + "get_gallery_details?secret=" + API_SECRET + "&id=" + id;
    return axios
      .post(eurl, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  createGallery(data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = SERVER_API_URL + "create_gallery?secret=" + API_SECRET;
    console.log("adding gallery::: ", url, data, { headers: hrs });
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  galleryEdit(data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = SERVER_API_URL + "edit_gallery?secret=" + API_SECRET;
    console.log("editing  _gallery::: ", url, data, { headers: hrs });
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  editGalleryItem(data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = SERVER_API_URL + "edit_gallery_item?secret=" + API_SECRET;
    console.log("editing  _gallery item::: ", url, data, { headers: hrs });
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  removeGalleryItem(id: any) {
    const url = SERVER_API_URL + "remove_gallery_item?secret=" + API_SECRET;
    console.log("removing gallery item::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  populateGallery(data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = SERVER_API_URL + "populate_gallery?secret=" + API_SECRET;
    console.log("populating gallery::: ", url, data, { headers: hrs });
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  publishGallery(options: any) {
    const url = SERVER_API_URL + "publish_gallery?secret=" + API_SECRET;
    console.log("publishing gallery::: ", url, options, { headers: this.auh });
    return axios.post(url, options, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  removeGallery(id: any) {
    const url = SERVER_API_URL + "remove_gallery?secret=" + API_SECRET;
    console.log("removing gallery::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  listExecutives(options: any) {
    const query_string = new URLSearchParams(options);

    const url = SERVER_API_URL + "list_executives?secret=" + API_SECRET;

    console.log("listing executives::: ", url, options, {
      headers: this.auh,
    });
    return axios.post(url, options).then((response) => {
      console.log("::faqres:::", response);
      return response.data;
    });
  }

  createExecutives(options: any) {
    const url = SERVER_API_URL + "create_executives?secret=" + API_SECRET;

    console.log("create executives::: ", url, options, {
      headers: this.auh,
    });
    return axios.post(url, options).then((response) => {
      console.log("::eecutives:::", response);
      return response.data;
    });
  }
  editExecutives(options: any) {
    const url = SERVER_API_URL + "edit_executives?secret=" + API_SECRET;

    console.log("edit executives::: ", url, options, {
      headers: this.auh,
    });
    return axios.post(url, options).then((response) => {
      console.log("::eecutives:::", response);
      return response.data;
    });
  }
  publishExecutives(options: any) {
    const query_string = new URLSearchParams(options);

    const url = SERVER_API_URL + "publish_executives?secret=" + API_SECRET;

    console.log("publish executives::: ", url, options, {
      headers: this.auh,
    });
    return axios.post(url, options).then((response) => {
      console.log("::eecutives:::", response);
      return response.data;
    });
  }

  removeExecutives(id: any) {
    const url = SERVER_API_URL + "remove_executives?secret=" + API_SECRET;

    console.log("removing executives::: ", url, id, {
      headers: this.auh,
    });
    return axios.post(url, { id: id }).then((response) => {
      console.log("::faqres:::", response);
      return response.data;
    });
  }
  getExecutivesDetails(id: any) {
    const eurl = SERVER_API_URL + "get_executives_details?secret=" + API_SECRET;

    console.log("fetching executives details::: ", eurl, id, {
      headers: this.auh,
    });
    return axios
      .post(eurl, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }
  getPage(page: string) {
    const url = SERVER_API_URL + "get_page?secret=" + API_SECRET;
    console.log("fetching page::: ", url, page, { headers: this.auh });
    return axios
      .post(url, { slug: page }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }
  contactMessage(data: any) {
    const url = SERVER_API_URL + "contact_message?secret=" + API_SECRET;
    console.log("sending message::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  listFaq(is_php = false) {
    const url = is_php
      ? SERVER_API_URL + "custom/faq"
      : SERVER_API_URL + "list_faq?secret=" + API_SECRET;
    const data = is_php ? { mode: "all-faqs" } : null;
    console.log("listing faqs::: ", url, data, { headers: this.auh });
    if (is_php) {
      return axios.post(url, data, { headers: this.auh }).then((response) => {
        console.log("::faqres:::", response);
        return response.data;
      });
    } else {
      return axios
        .post(url, { mode: "all-faqs" }, { headers: this.auh })
        .then((response) => {
          console.log("::faqres:::", response);
          return response.data;
        });
    }
  }
  getFaqDetails(id: string) {
    const url = SERVER_API_URL + "get_faq_details?secret=" + API_SECRET;
    console.log("fetching faq details::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  createFaq(data: any) {
    const url = SERVER_API_URL + "create_faq?secret=" + API_SECRET;
    console.log("adding faq details::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  editFaq(data: any) {
    const url = SERVER_API_URL + "edit_faq?secret=" + API_SECRET;
    console.log("editing details::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  removeFaq(id: any) {
    const url = SERVER_API_URL + "remove_faq?secret=" + API_SECRET;
    console.log("removing details::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  getHomepage() {
    const url = SERVER_API_URL + "get_home_page?secret=" + API_SECRET;
    console.log("removing details::: ", url, { headers: this.auh });
    return axios.get(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  //

  fetchCountries() {
    const url = SERVER_API_URL + "fetch_countries?secret=" + API_SECRET;
    console.log("fetching countries::: ", url, { headers: this.auh });
    return axios.get(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  fetchDashboard() {
    const url = SERVER_API_URL + "dashboard?secret=" + API_SECRET;
    console.log("fetching dashboard::: ", url, { headers: this.auh });
    return axios.get(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  //

  listPages() {
    const url = SERVER_API_URL + "list_pages?secret=" + API_SECRET;
    console.log("listing pages::: ", url, { headers: this.auh });
    return axios.post(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  getPagesDetails(id: string) {
    const url = SERVER_API_URL + "get_pages_details?secret=" + API_SECRET;
    console.log("fetching pages details::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  createPages(data: any) {
    const url = SERVER_API_URL + "create_pages?secret=" + API_SECRET;
    console.log("adding page details::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  editPages(data: any) {
    const url = SERVER_API_URL + "edit_pages?secret=" + API_SECRET;
    console.log("editing page::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  removePages(id: any) {
    const url = SERVER_API_URL + "remove_pages?secret=" + API_SECRET;
    console.log("removing page::: ", url, id, { headers: this.auh });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  //
  editHomepage(data: any) {
    const url = SERVER_API_URL + "edit_home_page?secret=" + API_SECRET;
    console.log("editing home page::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  //

  listSettings() {
    const url = SERVER_API_URL + "list_settings?secret=" + API_SECRET;
    console.log("listing settings::: ", url, { headers: this.auh });
    return axios.post(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  //Whitepaper

  listWhitepaper() {
    const url = SERVER_API_URL + "list_whitepaper?secret=" + API_SECRET;
    console.log("listing whitepaper::: ", url, { headers: this.auh });
    return axios.post(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  listNewsCategories() {
    const url = SERVER_API_URL + "list_news_categories?secret=" + API_SECRET;
    console.log("listing news categories::: ", url, {
      headers: this.auh,
    });
    return axios.post(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  getWhitepaperDetails(id: string) {
    const url = SERVER_API_URL + "get_whitepaper_details?secret=" + API_SECRET;
    console.log("fetching whitepaper details::: ", url, id, {
      headers: this.auh,
    });
    return axios
      .post(url, { id: id }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  createNews(data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = SERVER_API_URL + "create_news?secret=" + API_SECRET;
    console.log("adding news::: ", url, data, { headers: hrs });
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  editNews(data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    const url = SERVER_API_URL + "edit_news?secret=" + API_SECRET;
    console.log("editing news::: ", url, data, { headers: hrs });
    return axios.post(url, data, { headers: hrs }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  getWhitepaperPage(data: any) {
    const url = SERVER_API_URL + "get_whitepaper_page?secret=" + API_SECRET;
    console.log("get_whitepaper_page::: ", url, data, { headers: this.auh });
    return axios.post(url, data, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  verifyRegistration(params: any) {
    const url = SERVER_API_URL + "verify_registration?secret=" + API_SECRET;
    console.log("verifying_registration::: ", url, {
      headers: this.auh,
    });
    return axios.post(url, params, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  confirmPasswordReset(params: any) {
    const url = SERVER_API_URL + "confirm_password_reset?secret=" + API_SECRET;
    console.log("confirm_password_reset::: ", url, params, {
      headers: this.auh,
    });
    return axios.post(url, params, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  updateProfile(usr: any) {
    const url = SERVER_API_URL + "update_profile?secret=" + API_SECRET;
    console.log("updating_profile::: ", url, {
      headers: this.auh,
    });
    return axios.post(url, usr, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  queryTransaction(load: any) {
    const url = SERVER_API_URL + "query_transaction?secret=" + API_SECRET;
    console.log("query_transaction::: ", url, {
      headers: this.auh,
    });
    return axios.post(url, load, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  changePassword(usr: any) {
    const url = SERVER_API_URL + "change_password?secret=" + API_SECRET;
    console.log("change_password::: ", url, {
      headers: this.auh,
    });
    return axios.post(url, usr, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  fetchmethods() {
    const url = SERVER_API_URL + "fetchmethods?secret=" + API_SECRET;
    console.log("fetching methods::: ", url, { headers: this.auh });
    return axios.get(url, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  payPaystack(load: any) {
    const url = SERVER_API_URL + "pay_paystack?secret=" + API_SECRET;
    console.log("posting paystack::: ", load, url);
    return axios.post(url, load, { headers: this.auh }).then((response) => {
      return response.data;
    });
  }
  payFlutterwave(load: any) {
    const url = SERVER_API_URL + "pay_flutterwave?secret=" + API_SECRET;
    console.log("posting flutterwave::: ", load, url);
    return axios.post(url, load, { headers: this.auh }).then((response) => {
      return response.data;
    });
  }

  initiatePay() {
    const url = SERVER_API_URL + "initiatepay?secret=" + API_SECRET;
    console.log("initiating pay::: ", url, { headers: this.auh });
    return axios.post(url, {}, { headers: this.auh }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  fetchBonus(code: any) {
    const url = SERVER_API_URL + "fetchbonus?secret=" + API_SECRET;
    console.log("fetching bonus::: ", `${url}&code=${code}`, {
      headers: this.auh,
    });
    return axios
      .post(`${url}`, { code: code }, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  callApiPost(endpoint: string, load: any = {}) {
    const url = SERVER_API_URL + endpoint + "?secret=" + API_SECRET;
    console.log("calling api post ::: ", `${url}`, {
      headers: this.auh,
    });
    return axios
      .post(`${url}`, load, { headers: this.auh })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  markCryptoPayment(load: any) {
    const url =
      SERVER_API_URL +
      "mark-crypto-payment?secret=" +
      process.env.REACT_APP_API_SECRET;
    console.log("marking::: ", url);
    return axios.post(url, load).then((response) => {
      return response.data;
    });
  }

  findAccount(load: any) {
    const url = API_URL + "/auth";
    return axios.post(url, load).then((response) => {
      return response.data;
    });
  }
  registerUser(load: any) {
    const url =
      SERVER_API_URL +
      "register-user?secret=" +
      process.env.REACT_APP_API_SECRET;
    console.log("registering::: ", url);
    load._token = "{{ csrf_token() }}";
    return axios.post(url, load).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  resendVerify(load: any) {
    console.log(load);
    const url =
      SERVER_API_URL +
      "resend-verify?secret=" +
      process.env.REACT_APP_API_SECRET;
    console.log("resending::: ", url);
    return axios.post(url, load).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  forgotReset(load: any) {
    const url =
      SERVER_API_URL +
      "forgot-reset?secret=" +
      process.env.REACT_APP_API_SECRET;
    console.log("resending::: ", url);
    return axios.post(url, load).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  loginUser(load: any) {
    const url =
      SERVER_API_URL + "login_user?secret=" + process.env.REACT_APP_API_SECRET;
    console.log("fetching::: ", url);
    return axios.post(url, load).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  checkSession() {
    const url =
      SERVER_API_URL +
      "check-session?secret=" +
      process.env.REACT_APP_API_SECRET;
    console.log("fetching::: ", url);
    return axios.get(url, { headers: this.auh }).then((response) => {
      let resp = response.data;
      return resp;
    });
  }

  post(data: any, endpoint: string) {
    const url = API_URL + endpoint;
    return axios.post(url, data).then((response) => {
      return response.data;
    });
  }
  get(endpoint: string) {
    return axios.post(API_URL + endpoint).then((response) => {
      return response.data;
    });
  }
  postHeader(endpoint: string, data: any) {
    return axios
      .post(API_URL + endpoint, data, { headers: this.auh })
      .then((response) => {
        return response.data;
      });
  }
  postForm(endpoint: string, data: any) {
    const obh = { "content-type": "multipart/form-data" };
    return axios
      .post(API_URL + endpoint, data, { headers: obh })
      .then((response) => {
        return response.data;
      });
  }
  postFormHeader(endpoint: string, data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    return axios
      .post(API_URL + endpoint, data, { headers: hrs })
      .then((response) => {
        return response.data;
      });
  }
  getHeader(endpoint: string) {
    return axios
      .get(API_URL + endpoint, { headers: this.auh })
      .then((response) => {
        let resp = response.data;
        return resp;
      });
  }
}
export default new HttpService();
