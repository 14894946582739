import React from "react";
import SeoModule from "../../../services/SeoModule";
import LoginModule from "./LoginModule";

const Login = () => {
  return (
    <React.Fragment>
      <SeoModule
        title="Urum- Login"
        description="Login Page"
        name="Urum"
        type="Crypto Investment"
      />
      <section className="page-main">
        <div className="flex flex-col align-items-center">
          <div className="auth-logo-space">
            <a className="logo" href="/">
              <img src={`/images/logo.png`} alt="Urum Logo" />
            </a>
          </div>
        </div>

        <div className="flex flex-col flex-column justify-content-center align-items-center py20 px10">
          <div className="login-pane">
            <LoginModule />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
