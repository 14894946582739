import React from "react";
import { Grid } from "@mui/material";
import { HomeContext } from "../../../services/HomeContext";
import * as processHtml from "../../../services/processHtml";

const Thinking = () => {
  const [home, setHome] = React.useContext(HomeContext);
  React.useEffect(() => {
    console.log(home, "::home");
  }, [home]);
  const { decodeHtml } = processHtml;
  return (
    <React.Fragment>
      {/*--Start counter-area  --*/}
      <div className="thinking-container">
        <div className="container">
          <div className="flex flex-row-resp align-items-center">
            <div className="thinking-desc spacer">
              {home?.igwe_section ? (
                <h2
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(home?.igwe_section?.header),
                  }}
                ></h2>
              ) : (
                <h2>Message from Igwe</h2>
              )}
              {/**<p>
                Building with our residents is something we keep close to our
                heart. Getting to know their personal stories and celebrating
                them together is what makes the care we provide truly
                personalized.
  </p>**/}
              <div className="fearture-pads">
                <Grid container spacing={2.5}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="pad-container my30">
                      {home?.igwe_section ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(home?.igwe_section?.message),
                          }}
                        ></p>
                      ) : (
                        <p>Message from Igwe</p>
                      )}
                      <div className="pad-head">
                        <span className="pad-icon">
                          <i className="fas fa-pen"></i>
                        </span>
                        <div className="pad-text spacer">
                          <div className="sub">Signed</div>
                          {home?.igwe_section ? (
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: decodeHtml(home?.igwe_section?.name),
                              }}
                            ></h3>
                          ) : (
                            <h3>
                              HRH Igwe (Dr.) Benedict Ofozoba Nweke
                              (Ezeugbonyimba II; Igwe Urum Anike)
                            </h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/*--thinking-desc ends  --*/}
            <div className="thinking-banner spacer">
              <img src="/images/igwe.png" alt="" />
            </div>
            {/*--thinking-banner ends  --*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Thinking;
