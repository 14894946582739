import React from "react";
import Card from "@mui/material/Card";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import Header from "../../Header/Header";
import PageHeader from "../PageHeader";
import { LinearProgress } from "@mui/material";

const History = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({ slug: "history" });

  return (
    <React.Fragment>
      <SeoModule
        title="Urum - Urum History"
        description="History of Urum"
        name="Urum"
        page_url={process.env.REACT_APP_PUBLIC_DOMAIN}
        page_image={process.env.REACT_APP_PUBLIC_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />

      <div className="main-bg-grad page-top-cover">
        <div className="home-overlay"></div>
        <Header />
        <PageHeader
          page={{
            title: "Urum History",
            description: "&nbsp;",
          }}
        />
      </div>

      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="pxy20">
              {loading_page && (
                <div className="pxy20">
                  <LinearProgress />
                </div>
              )}
              {loaded_page && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(page.description),
                  }}
                ></div>
              )}
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default History;
