import React from "react";
import HttpService from "./HttpService";

const HomeContext = React.createContext<any>(null);

const HomeProvider = ({ children }: any) => {
  const [home, setHome] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getHome();
  }, []);
  const getHome = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.getHomepage()
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          setHome(result.data);
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax
  return (
    <HomeContext.Provider value={[home, setHome]}>
      {children}
    </HomeContext.Provider>
  );
};

export { HomeContext, HomeProvider };
