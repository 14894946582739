import React from "react";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import GalleryListTemplate from "../../templates/GalleryListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";

import { Breadcrumbs, Card, Icon } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

//

const FILE_URL = process.env.REACT_APP_PUBLIC_DOMAIN;

//
const GalleryList = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [gallery, setGallery] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [result_finished, setResultFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    listGallery(offset);
  }, []);

  const listGallery = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.listGallery({
      offset: offset,
      limit: 12,
      is_laravel: true,
      is_admin: true,
    })
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            if (result.data.length === 0) {
              setResultFinished(true);
            } else {
              let newRes = [...gallery, ...result.data];
              setGallery(newRes);
            }
          } else {
            setGallery([]);
          }
        },
        (error) => {
          setGallery([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listGallery(newOffset);
  };
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/admin/gallery/new`);
  };

  const getClass = () => {
    let items = ["g1", "g2", "g3", "g4", "g5", "g6"];
    return items[Math.floor(Math.random() * items.length)];
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/gallery">Gallery</Link>
                  </Breadcrumbs>
                  <h2>Gallery</h2>
                </div>
              </div>
            </div>
            <div className="flex flex-row border-bottom align-items-center pxy10">
              <span className="spacer"></span>
              <span>
                <Button variant="outlined" size="small" onClick={launchNew}>
                  New
                </Button>
              </span>
            </div>
            <section className="pxy10">
              {gallery.length > 0 && (
                <div className="gallery-grid z-high">
                  {gallery.map((item: any, index: number) => (
                    <Link to={`/admin/gallery/p/${item.id}`} key={index}>
                      <div className="gallery-content-pane">
                        {/*bLF90M96m2Q */}
                        {item.type === "video" && (
                          <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${
                              FILE_URL + item.src
                            }`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        )}
                        {(item.type === "image" ||
                          item.type === "both" ||
                          !item.type) && (
                          <img src={FILE_URL + item.src} alt={item.caption} />
                        )}
                      </div>
                      <div className="name-pane">{item.caption}</div>
                    </Link>
                  ))}
                </div>
              )}

              {/*      {loaded && (
                <GalleryListTemplate
                  gallery_list={gallery}
                  loaded={loaded}
                  loading={loading}
                />
              )} */}
              {loading && (
                <PlaceHolder
                  type="block_list"
                  card_height={250}
                  card_border_radius={10}
                />
              )}
              {loaded && (
                <div className="flex justify-content-center align-items-center py20">
                  <Button
                    disabled={loading || result_finished}
                    size="large"
                    variant="contained"
                    onClick={loadMore}
                  >
                    {loading ? "Loading..." : "Load More"}{" "}
                    <Icon>
                      {loading ? (
                        <i className="fas fa-spin fa-circle-notch"></i>
                      ) : (
                        <ArrowDownwardOutlined />
                      )}
                    </Icon>
                  </Button>
                </div>
              )}
            </section>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default GalleryList;
