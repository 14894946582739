import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Dialog,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Add from "@mui/icons-material/Add";
import LoginModule from "../Public/Login/LoginModule";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PromptLogin = (props: any) => {
  const { button_text, return_url, mode, button_class } = props;
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [modal, setModal] = React.useState({
    onopen: false,
    onclose: closeModal,
    title: "User Login",
  });
  const launchModal = () => {
    setModal({ ...modal, onopen: true, onclose: closeModal });
  };
  return (
    <>
      {mode === "large_button" ? (
        <Button
          disabled={loading}
          onClick={launchModal}
          variant="outlined"
          color="warning"
          size="large"
          fullWidth
        >
          <Add /> {loading ? "Working..." : button_text}
        </Button>
      ) : mode === "custom" ? (
        <button
          disabled={loading}
          onClick={launchModal}
          className={button_class}
        >
          <i className="fas fa-plus"></i> {loading ? "Working..." : button_text}
        </button>
      ) : (
        <a
          className="btn btn-primary btn-outlined btn-small"
          onClick={launchModal}
        >
          <i className="fas fa-plus"></i>{" "}
          {loading ? " Working..." : button_text}
        </a>
      )}

      <Dialog
        fullScreen={false}
        TransitionComponent={Transition}
        open={modal.onopen}
        onClose={modal.onclose}
        scroll={"paper"}
        aria-labelledby={"Me"}
      >
        <Toolbar
          sx={{ p: "0 10px 0 20px !important", minHeight: "60px !important" }}
        >
          <IconButton edge="start" color="inherit" aria-label="close">
            <InfoOutlined />
          </IconButton>
          <Typography sx={{ ml: "0px", flex: 1 }} variant="h6" component="div">
            Please Login or Register to continue
          </Typography>
        </Toolbar>
        <DialogContent>
          <section>
            <LoginModule />
          </section>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={modal.onclose} color="warning">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(PromptLogin);
