import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import DatePipe from "../../../pipes/DatePipe";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import Comments from "../../templates/Comments";
import PageHeader from "../PageHeader";
import Header from "../../Header/Header";
import { ListItemButton, Skeleton } from "@mui/material";
import PlaceHolder from "../../templates/PlaceHolder";
const NewsDetail = () => {
  let params: any = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [news, setNews] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.url ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchNews(params.url);
    }
  }, [params]);

  const fetchNews = (url: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.getNewsDetails(url)
      .then(
        (result) => {
          console.log(result);
          setNews(result.data);
        },
        (error) => {
          setNews({ related_articles: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const update_coms = (data: any) => {
    const list = [...news.comments];
    list.unshift(data);
    setNews({ ...news, comments: list });
    //setNews;
  };
  const return_date = (idate: any) => {
    return <DatePipe value={idate * 1000} />;
  };
  return (
    <React.Fragment>
      <div className="main-bg-grad page-top-cover">
        <div className="home-overlay"></div>
        <Header />
        <PageHeader
          loading={loading}
          page={{
            title: decodeHtml(news?.title),
            date: news?.create_date,
          }}
        />
      </div>

      <section className="page-main">
        {loading ? (
          <div className="container">
            <PlaceHolder type="fullpage" />
          </div>
        ) : (
          <div className="container">
            <Grid
              container
              rowSpacing={0}
              columnSpacing={0}
              sx={{
                display: "flex",
                flexGrow: "1",
                alignContent: "stretch",
                marginTop: "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={8} sx={{ m: "0", p: "0" }}>
                <div className="news-main">
                  {news?.photo && (
                    <div className="news-banner">
                      <img
                        src={process.env.REACT_APP_PUBLIC_DOMAIN + news?.photo}
                        alt="news banner"
                      />
                    </div>
                  )}

                  <div
                    className="news-body"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(news?.content),
                    }}
                  ></div>

                  {news?.comments && (
                    <>
                      <PostCommentTemplate
                        item={news}
                        update_coms={update_coms}
                        id={news.id}
                        index={0}
                        type={"news"}
                      />

                      <Comments
                        loaded={loaded}
                        loading={loading}
                        data={news.comments}
                        platform={"news"}
                      />
                    </>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Card className={"ml-20-resp"}>
                  <div className="related-card-header">
                    <h3>Related News</h3>
                  </div>
                  {news?.related_articles.map((item: any) => (
                    <ListItem
                      divider={true}
                      component={NavLink}
                      to={`/articles/${item.url}`}
                      key={item.id}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              borderRadius: "5px",
                              height: "60px",
                              width: "60px",
                              mr: "10px",
                            }}
                            variant="square"
                            src={
                              process.env.REACT_APP_PUBLIC_DOMAIN + item.thumb
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<h4 className="list-link">{item.title}</h4>}
                          secondary={
                            <div className="date-space">
                              <i className="fas fa-clock"></i>{" "}
                              <DatePipe value={item.create_date * 1000} />
                            </div>
                          }
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default NewsDetail;
