import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import useFetchNewsCategories from "../../../hooks/useFetchNewsCategories";

const ExecutivesNew = () => {
  let navigate = useNavigate();
  let [executives, setExecutives] = React.useState<any>({ designation: "0" });
  const { news_categories } = useFetchNewsCategories();
  let [bio, setContent] = React.useState<any>("");
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New Executive",
  });

  React.useEffect(() => {}, []);

  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(bio);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("is_current", executives?.is_current);
    formData.append("name", executives?.name);
    formData.append("designation", executives?.designation);
    formData.append("tenure", executives?.tenure);
    formData.append("banner", file);
    formData.append("bio", bio);

    HttpService.createExecutives(
      formData /* {
      title: executives?.title,
      designation: executives?.designation,
      bio: bio,
      mode: "add-executives",
    } */
    )
      .then((resp) => {
        console.log(resp);
        setModal({ ...modal, onopen: true, message: resp.message });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.name;
      setExecutives({ ...executives, [name]: e.target.value });
    },
    [executives]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashbaord</Link>
                    <Link to="/admin/executives">Executives</Link>
                  </Breadcrumbs>
                  <h2>Add Executives</h2>
                </div>
              </div>
            </div>

            <div className="py30 px20">
              <div className={loading ? " input iconed " : " input "}>
                <label>Full Name</label>
                <input
                  type="text"
                  className="input-form-control"
                  name="name"
                  onChange={handleInputChange}
                  placeholder={"Executive's Name "}
                />
              </div>

              <div className={loading ? " input iconed " : " input "}>
                <label>Position</label>
                <select
                  className="input-form-control"
                  onChange={handleInputChange}
                  name="designation"
                  defaultValue={"0"}
                >
                  <option value="0">Select Designation</option>
                  <option value="President General">President General</option>
                  <option value="Vice President General">
                    Vice President General
                  </option>
                  <option value="Second Vice President General">
                    Second Vice President General
                  </option>
                  <option value="Secretary General">Secretary General</option>

                  <option value="Assistant Secretary General">
                    Assistant Secretary General
                  </option>
                  <option value="Treasurer">Treasurer</option>
                  <option value="Financial Secretary">
                    Financial Secretary
                  </option>
                  <option value="Provost">Provost</option>
                  <option value="Provost1">Provost1</option>
                  <option value="Provost2">Provost2</option>
                  <option value="PRO">PRO</option>
                  <option value="PRO1">PRO1</option>
                  <option value="PRO2">PRO2</option>
                  <option value="PRO3">PRO3</option>
                </select>
              </div>
              <div className={loading ? " input iconed " : " input "}>
                <label>
                  Tenure Period <em>(Eg: 2020 - 2023 )</em>
                </label>
                <input
                  type="text"
                  className="input-form-control"
                  name="tenure"
                  disabled={loading}
                  onChange={handleInputChange}
                  placeholder={"Eg: 2020 - 2023 "}
                />
              </div>
              <div className={loading ? " input iconed " : " input "}>
                <label>Leadership Status</label>
                <select
                  className="input-form-control"
                  onChange={handleInputChange}
                  name="is_current"
                  defaultValue={"1"}
                >
                  <option value="1">Current Executive</option>
                  <option value="0">Past Leader</option>
                </select>
              </div>

              <div className="banner-section">
                {preview_image && (
                  <div className="image_preview">
                    <img className="" src={preview_image} alt="preview Image" />
                  </div>
                )}
                <div
                  className={
                    loading ? " input iconed mt20 pt10" : " input mt20 pt10"
                  }
                >
                  <label>Attach Executive's Picture</label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleFileChange}
                    placeholder={"picture"}
                  />
                </div>
              </div>

              <div className="mb10">
                <DefaultEditor
                  className="form-control"
                  placeholder="Executive's Biography"
                  value={bio}
                  onChange={onHtmlChange}
                />
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Add Executive"}
              </Button>
            </div>
          </Card>
        </div>
      </section>
      <CustomModal data={modal} />
    </React.Fragment>
  );
};

export default ExecutivesNew;
