import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import PlaceHolder from "../../templates/PlaceHolder";
import CustomModal from "../../templates/CustomModal";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Notifications(props: any) {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const doAjax = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("notifications", { mode: "all" })
      .then(
        (result: any) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            setNotifications(result.data);
          } else {
            setNotifications([]);
          }
        },
        (error) => {
          setNotifications([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  React.useEffect(() => {
    doAjax();
  }, []); //componentDidMount

  const htmlSafe = (value: string) => {
    return value.toUpperCase();
  };
  const setNumber = (value: any) => {
    return value.toFixed(2);
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };

  const [modal, setModal] = React.useState({
    title: "",
    onopen: false,
    onclose: closeModal,
    message: "",
    date: "",
  });
  const openModal = (item: any, value?: any) => {
    const idt = () => <DatePipe value={item.ndate} />;
    setModal({
      ...modal,
      title: "Notification Details",
      message: `${item.detail}`,
      date: item.ndate,
      onopen: true,
    });
  };

  const createMarkup = (text: any) => {
    return { __html: text };
  };

  return (
    <>
      <div
        className="page-top-intro flex px20 py10 align-items-center 
      justify--content-center"
      >
        <div className="px10">
          <h3 className="py0 my0 px0 mx0">
            <i className="fas fa-bell"></i> Notifications
          </h3>
        </div>
        <div className="spacer"></div>
        <div>--</div>
      </div>
      <Divider />
      {!loading && (
        <Card sx={{ p: "0", m: "10px" }}>
          {notifications.map((item: any, index: number) => (
            <ListItem
              button
              onClick={() => openModal(item)}
              key={item.id}
              divider={index === notifications.length - 1 ? false : true}
            >
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary={
                  <span dangerouslySetInnerHTML={createMarkup(item.detail)} />
                }
                secondary={<DatePipe value={item.ndate} />}
              />
            </ListItem>
          ))}
        </Card>
      )}

      {loading && (
        <>
          <div className="pxy20">
            <Card className="pxy20">
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
            </Card>
          </div>
        </>
      )}

      <CustomModal data={modal} />
    </>
  );
}

export default Notifications;
