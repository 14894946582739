import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";

const GalleryNew = (props: any) => {
  let navigate = useNavigate();
  let [gallery, setGallery] = React.useState<any>({ cat_id: "0" });
  let [description, setDescription] = React.useState<any>("");
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New Whitepaper",
  });

  React.useEffect(() => {}, []);

  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("caption", gallery?.caption);
    formData.append("banner", file);
    formData.append("description", description);
    formData.append("type", gallery?.type);

    HttpService.createGallery(
      formData /* {
      title: gallery?.title,
      cat_id: gallery?.cat_id,
      description: description,
      mode: "add-gallery",
    } */
    )
      .then((resp) => {
        console.log(resp);

        setModal({ ...modal, onopen: true, message: resp.message });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.name;
      setGallery({ ...gallery, [name]: e.target.value });
    },
    [gallery]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashbaord</Link>
                    <Link to="/admin/gallery">Gallery</Link>
                  </Breadcrumbs>
                  <h2>Add Gallery</h2>
                </div>
              </div>
            </div>

            <div className="py30 px20">
              <div className={loading ? " input iconed " : " input "}>
                <label>Title</label>
                <input
                  type="text"
                  className="input-form-control"
                  name="caption"
                  onChange={handleInputChange}
                  placeholder={"Gallery title "}
                />
              </div>
              <div className={loading ? " input iconed " : " input "}>
                <label>Gallery type</label>
                <select
                  className="input-form-control"
                  onChange={handleInputChange}
                  defaultValue="image"
                  name="type"
                >
                  <option value="image">Image Only</option>
                  <option value="video">Video Only</option>
                  <option value="both">Video & Image</option>
                </select>
              </div>

              <div className="banner-section">
                {preview_image && (
                  <div className="image_preview">
                    <img className="" src={preview_image} alt="preview Image" />
                  </div>
                )}
                <div
                  className={
                    loading ? " input iconed mt20 pt10" : " input mt20 pt10"
                  }
                >
                  <label>Attach Baner Picture</label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleFileChange}
                    placeholder={"details firstname "}
                  />
                </div>
              </div>

              <div className="mb10">
                <DefaultEditor
                  className="form-control"
                  placeholder="Gallery Details"
                  value={description}
                  onChange={onHtmlChange}
                />
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Add gallery "}
              </Button>
            </div>
          </Card>
        </div>
      </section>
      <CustomModal data={modal} />
    </React.Fragment>
  );
};

export default GalleryNew;
