import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import GalleryModal from "./GalleryModal";
const FILE_URL = process.env.REACT_APP_PUBLIC_DOMAIN;

const GalleryListTemplate = (props: any) => {
  const { gallery_list, loading, loaded } = props;

  const [list, setList] = React.useState<any[]>([]);
  React.useEffect(() => {
    console.log("::", gallery_list);
    setList(gallery_list);
  }, []);

  const onGalClose = () => {
    setGallery({ ...gallery, onopen: false });
  };
  const [gallery, setGallery] = React.useState<any>({
    onopen: false,
    onclose: onGalClose,
  });
  const launchGal = (data: any) => {
    return setGallery({ ...gallery, onopen: true, data: data });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3.5}>
        {list.map((item: any, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
            <Link to={`/gallery/${item.id}`} className="gallery-content-pane">
              <span
                onClick={() => launchGal({ video: "youtube" })}
                className="gallery-item-details"
              >
                {item.caption}
              </span>

              {item.type === "video" && (
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${item.video_id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              {(item.type === "image" || item.type === "both") && (
                <img src={FILE_URL + item.src} alt={item.caption} />
              )}
            </Link>
          </Grid>
        ))}
      </Grid>

      <GalleryModal {...gallery} />
    </React.Fragment>
  );
};

export default GalleryListTemplate;
