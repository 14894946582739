import React from "react";
import * as processHtml from "../../services/processHtml";
import Edit from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";
import DatePipe from "../../pipes/DatePipe";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import AuthService from "../../services/AuthService";

const Comments = (props: any) => {
  const cur_user = AuthService.getCurrentUser();
  console.log("CUR_USER::: ", cur_user);
  console.log("PROPS::: ", props);
  const {
    data,
    loading,
    loaded,
    is_admin,
    edit_action,
    delete_action,
    platform,
  } = props;
  const isAdmin = cur_user["is_admin"] ? true : false;

  const { decodeHtml } = processHtml;

  return (
    <React.Fragment>
      {loading && <div className="pxy10">Loading comments...</div>}
      {data && (
        <section>
          {data.map((item: any, index: number) => (
            <div className="comment-cover flex flex-row" key={index}>
              <div className="profiler">
                <div
                  className="profile-medium bg-white"
                  style={{
                    backgroundImage: item.photo
                      ? process.env.REACT_APP_PUBLIC_DOMAIN + item.photo
                      : "{`/images/logo.png`}",
                  }}
                ></div>
              </div>
              <div className="comment-content">
                <div>
                  <h4 style={{ lineHeight: "1.01" }}>{item.name}</h4>
                  <span className="date-space spacer">
                    <i className="fas fa-clock"></i>{" "}
                    <DatePipe value={item.create_date * 1000} />
                  </span>
                </div>
                <div className="comment-body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(item.comment),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
    </React.Fragment>
  );
};

export default Comments;
