import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { DefaultEditor } from "react-simple-wysiwyg";
import * as processHtml from "../../../services/processHtml";
import Edit from "@mui/icons-material/Edit";
import HttpService from "../../../services/HttpService";
import PlaceHolder from "../../templates/PlaceHolder";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import CustomModal from "../../templates/CustomModal";
import Delete from "@mui/icons-material/Delete";
import ConfirmModal from "../../templates/ConfirmModal";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import DatePipe from "../../../pipes/DatePipe";
import { Avatar, ListItemAvatar } from "@mui/material";
import { ToggleOff, ToggleOn } from "@mui/icons-material";

export default function ArticlesList() {
  let navigate = useNavigate();
  const { decodeHtml, truncateWord } = processHtml;
  const [articles, setArticles] = React.useState<any[]>([]);
  const [offset, setOffset] = React.useState<number>(0);
  const [result_finished, setResultFinished] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit articles",
  });

  React.useEffect(() => {
    listarticles();
  }, []);

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listarticles();
  };

  const listarticles = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.listNews({
      offset: offset,
      limit: 24,
      is_laravel: true,
      is_admin: true,
    })
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result.data)) {
            if (result.data.length === 0) {
              setResultFinished(true);
            } else {
              const newr = [...articles];
              setArticles([...newr, ...result.data]);
            }
          } else {
            setArticles(articles);
          }
        },
        (error) => {
          setArticles(articles);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...articles];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setArticles(mutd);
  };

  const togEdit = (i: any) => {
    return navigate(`/admin/articles/e/${i.id}`);
  };

  const launchNew = (i: any) => {
    return navigate(`/admin/articles/new`);
  };

  const delete_articles = (item: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.removeNews(item.id)
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({ ...modal, onopen: true, message: resp.message });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({ ...modal, onopen: true, message: "Error" });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  const publish_articles = (item: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.publishNews(item)
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({ ...modal, onopen: true, message: resp.message });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({ ...modal, onopen: true, message: "Error" });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete articles",
  });
  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      message: "Are you sure you want to remove this article?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_articles,
    });
  };
  const launchPublish = (item: any) => {
    const act = item.is_published == "1" ? "unpublish" : "publish";
    setDelModal({
      ...del_modal,
      id: item.id,
      action: act,
      title: act + " article",
      message: `Are you sure you want to ${act} this article?`,
      onopen: true,
      onclose: closeDelModal,
      onaccept: () => publish_articles({ id: item.id, action: act }),
    });
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          {" "}
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/articles">Articles</Link>
                  </Breadcrumbs>
                  <div className="flex flex-row align-items-center">
                    <h2>Articles</h2>
                    <span className="spacer"></span>
                    <span>--</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row border-bottom align-items-center pxy10">
              <span className="spacer"></span>
              <span>
                <Button variant="outlined" size="small" onClick={launchNew}>
                  New
                </Button>
              </span>
            </div>
            {articles.map((item: any, index: number) => (
              <ListItem
                disablePadding
                key={index}
                divider={index < articles.length - 1 ? true : false}
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        borderRadius: "5px",
                        height: "60px",
                        width: "60px",
                        mr: "10px",
                      }}
                      variant="square"
                      src={process.env.REACT_APP_PUBLIC_DOMAIN + item.thumb}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div className="flex flex-row align-items-center">
                        <h4 className="spacer" style={{ lineHeight: "1" }}>
                          <Link to={`/admin/articles/e/${item.url}`}>
                            {item.title}
                          </Link>
                        </h4>
                        <span>
                          <Tooltip title="Edit page">
                            <IconButton
                              size="medium"
                              onClick={() => togEdit(item)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove article">
                            <IconButton
                              color="warning"
                              size="medium"
                              onClick={() => launchDelete(item)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              item.is_published == "1"
                                ? "Unpublish article"
                                : "Publish Article"
                            }
                          >
                            <IconButton
                              color={
                                item.is_published == "1" ? "default" : "primary"
                              }
                              size="medium"
                              onClick={() => launchPublish(item)}
                            >
                              {item.is_published == "1" ? (
                                <ToggleOff />
                              ) : (
                                <ToggleOn />
                              )}
                            </IconButton>
                          </Tooltip>
                        </span>
                      </div>
                    }
                    secondary={
                      <span className="date-span">
                        <DatePipe value={item.create_date * 1000} />
                      </span>
                    }
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            {loading && <PlaceHolder type="horizontal_list" />}

            {loaded && (
              <div className="flex justify-content-center align-items-center py20">
                <Button
                  disabled={loading || result_finished}
                  size="large"
                  variant="contained"
                  onClick={loadMore}
                >
                  {loading ? "Loading..." : "Load More"}{" "}
                  <Icon>
                    {loading ? (
                      <i className="fas fa-spin fa-circle-notch"></i>
                    ) : (
                      <ArrowDownwardOutlined />
                    )}
                  </Icon>
                </Button>
              </div>
            )}
          </Card>
        </div>
      </section>
      <CustomModal data={modal} />
      <ConfirmModal data={del_modal} loading={loading} />
    </React.Fragment>
  );
}
