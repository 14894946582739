import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import DatePipe from "../../../pipes/DatePipe";
import PostCommentTemplate from "../../templates/PostCommentTemplate";
import Comments from "../../templates/Comments";
import PageHeader from "../PageHeader";
import Header from "../../Header/Header";
import { ListItemButton } from "@mui/material";
import SeoModule from "../../../services/SeoModule";
const ExecutivesDetails = () => {
  let params: any = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [executives, setExecutives] = React.useState<any>({
    related_executives: [],
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.id ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchExecutives(params.id);
    }
  }, [params]);

  const fetchExecutives = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.getExecutivesDetails(id)
      .then(
        (result) => {
          console.log(result);
          setExecutives(result.data);
        },
        (error) => {
          setExecutives({ related_executives: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const update_coms = (data: any) => {
    const list = [...executives.comments];
    list.unshift(data);
    setExecutives({ ...executives, comments: list });
    //setExecutives;
  };

  return (
    <React.Fragment>
      <SeoModule
        title={`Urum - Executives - ${decodeHtml(executives.name)}`}
        description="Fintech: banking, crypto, investments"
        name="Urum"
        page_url={process.env.REACT_APP_PUBLIC_DOMAIN}
        page_image={process.env.REACT_APP_PUBLIC_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <div className="main-bg-grad page-top-cover">
        <div className="home-overlay"></div>
        <Header />
        <PageHeader
          page={{
            title: decodeHtml(executives.name),
            description: executives.designation,
          }}
        />
      </div>
      <section className="page-main">
        <div className="container py30">
          {loaded && (
            <>
              <div className="py">
                <section>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={0}
                    sx={{
                      display: "flex",
                      flexGrow: "1",
                      alignContent: "stretch",
                      marginTop: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={8} sx={{ m: "0", p: "0" }}>
                      <div
                        className="px20 pb20"
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(executives.bio),
                        }}
                      ></div>

                      {executives.comments && (
                        <div className="pxy20">
                          <div className="px10 bordered border-radius my10">
                            <PostCommentTemplate
                              item={executives}
                              update_coms={update_coms}
                              id={executives.id}
                              index={0}
                              type={"executives"}
                            />
                          </div>

                          <Comments
                            loaded={loaded}
                            loading={loading}
                            data={executives.comments}
                            platform={"executives"}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Card className={"ml-20-resp"}>
                        <div className="related-card-header">
                          <h3>More Executives</h3>
                        </div>
                        {executives.related_executives.map((item: any) => (
                          <ListItem
                            disablePadding
                            divider={true}
                            component={NavLink}
                            to={`/executives/${item.id}`}
                            key={item.id}
                          >
                            <ListItemButton>
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    borderRadius: "5px",
                                    height: "60px",
                                    width: "60px",
                                    mr: "10px",
                                  }}
                                  variant="square"
                                  src={
                                    process.env.REACT_APP_PUBLIC_DOMAIN +
                                    item.thumb
                                  }
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <h4 className="list-link">{item.name}</h4>
                                }
                                secondary={
                                  <div className="date-space">
                                    <i className="fas fa-user-tie"></i>{" "}
                                    {item.designation}
                                  </div>
                                }
                              ></ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </Card>
                    </Grid>
                  </Grid>
                </section>
              </div>
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default ExecutivesDetails;
