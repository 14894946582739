import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import ExecutivesList from "./ExecutivesList";
import ExecutivesNew from "./ExecutivesNew";
import ExecutivesEdit from "./ExecutivesEdit";

const Executives = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/admin/properties/a/new`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<ExecutivesList />} />
        <Route path="/" element={<ExecutivesList />} />
        <Route path="/new" element={<ExecutivesNew />} />
        <Route path="/e/:eId" element={<ExecutivesEdit />} />
      </Routes>
    </React.Fragment>
  );
};

export default Executives;
