import React, { createContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { DefaultEditor } from "react-simple-wysiwyg";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import * as processHtml from "../../../services/processHtml";
import Edit from "@mui/icons-material/Edit";
import HttpService from "../../../services/HttpService";
import PlaceHolder from "../../templates/PlaceHolder";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import HtmlModal from "../../templates/HtmlModal";
import CustomModal from "../../templates/CustomModal";
import Delete from "@mui/icons-material/Delete";
import ConfirmModal from "../../templates/ConfirmModal";
import HomeMain from "../../Public/Home/HomeMain";
import Cards from "../../Public/Home/Cards";
import Thinking from "../../Public/Home/Thinking";
import PgIntro from "../../Public/Home/PgIntro";
import Profiles from "../../Public/Home/Profiles";
import LeaveMessage from "../../Public/Home/LeaveMessage";
import HomeNews from "../../Public/Home/HomeNews";
import Faq from "../Faq/Faq";
import FAQ from "../../Public/FAQ/FAQ";
import Footer from "../../Footer/Footer";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Slide from "@mui/material/Slide";
import { HomeContext } from "../../../services/HomeContext";
import { Breadcrumbs } from "@mui/material";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function IgweSection() {
  let navigate = useNavigate();
  const { decodeHtml, truncateWord } = processHtml;
  const [home, setHome] = React.useContext(HomeContext);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [header, setHeader] = React.useState<any>("");
  const [name, setName] = React.useState<any>("");
  const [message, setMessage] = React.useState<any>("");
  const [description, setDescription] = React.useState<any>("");
  React.useEffect(() => {
    console.log(home, "::home");
    setHeader(home?.igwe_section?.header);
    setDescription(home?.igwe_section?.message);
    setName(home?.igwe_section?.name);
  }, [home]);

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit homepage",
  });

  const handleSubmit = () => {
    const data = {
      header: header,
      message: description,
      name: name,
      mode: "igwe_section",
    };
    console.log(data, "::Data");
    setLoading(true);
    setLoaded(false);
    HttpService.editHomepage(data)
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({ ...modal, onopen: true, message: resp.message });
          }
          setTimeout(() => {
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({ ...modal, onopen: true, message: "Error" });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const [field, setField] = React.useState<any>("");
  const [field_value, setValue] = React.useState<any>("");
  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/homepage">Edit Homepage</Link>
                  </Breadcrumbs>
                  <h2>Igwe Section</h2>
                </div>
              </div>
            </div>

            <section className="pxy20">
              <div className="py30 px20">
                <div className={loading ? " input iconed " : " input "}>
                  <label>Intro Header</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="field"
                    value={header}
                    onChange={(e) => setHeader(e.target.value)}
                  />
                </div>

                <div className={loading ? " input iconed " : " input "}>
                  <label>Igwe's full name</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="field"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="mb10 input flex">
                  <textarea
                    className="input-form-control"
                    placeholder="Message"
                    rows={5}
                    value={description}
                    onChange={onHtmlChange}
                  ></textarea>
                </div>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? "Working..." : " Update "}
                </Button>
              </div>
            </section>
          </Card>
        </div>
      </section>

      <CustomModal data={modal} />
    </React.Fragment>
  );
}
