import React from "react";
import { Routes, Route } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import ExecutivesDetail from "./ExecutivesDetail";
import ExecutivesList from "./ExecutivesList";

const Executives = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<ExecutivesList />} />
        <Route path="/:id" element={<ExecutivesDetail />} />
      </Routes>
    </React.Fragment>
  );
};

export default Executives;
