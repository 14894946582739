import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import useFetchNewsCategories from "../../../hooks/useFetchNewsCategories";

const ArticlesNew = () => {
  let navigate = useNavigate();
  let [news, setNews] = React.useState<any>({ cat_id: "0" });
  const { news_categories } = useFetchNewsCategories();
  let [content, setContent] = React.useState<any>("");
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New News",
  });

  React.useEffect(() => {}, []);

  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(content);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("title", news?.title);
    formData.append("cat_id", news?.cat_id);
    formData.append("banner", file);
    formData.append("content", content);

    HttpService.createNews(
      formData /* {
      title: news?.title,
      cat_id: news?.cat_id,
      content: content,
      mode: "add-news",
    } */
    )
      .then((resp) => {
        console.log(resp);
          setModal({ ...modal, onopen: true, message: resp.message });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.name;
      setNews({ ...news, [name]: e.target.value });
    },
    [news]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashbaord</Link>
                    <Link to="/admin/articles">Articles</Link>
                  </Breadcrumbs>
                  <h2>Add Article</h2>
                </div>
              </div>
            </div>

            <div className="py30 px20">
              <div className={loading ? " input iconed " : " input "}>
                <label>Title</label>
                <input
                  type="text"
                  className="input-form-control"
                  name="title"
                  onChange={handleInputChange}
                  placeholder={"Article title "}
                />
              </div>

              <div className={loading ? " input iconed " : " input "}>
                <label>Category</label>
                <select
                  className="input-form-control"
                  onChange={handleInputChange}
                  name="cat_id"
                  defaultValue={"0"}
                >
                  <option value="0">Main Category</option>
                  {news_categories.map((itm: any, ind: number) => (
                    <option value={itm.id}>{itm.title}</option>
                  ))}
                </select>
              </div>

              <div className="banner-section">
                {preview_image && (
                  <div className="image_preview">
                    <img className="" src={preview_image} alt="preview Image" />
                  </div>
                )}
                <div
                  className={
                    loading ? " input iconed mt20 pt10" : " input mt20 pt10"
                  }
                >
                  <label>Attach Page Banner</label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleFileChange}
                    placeholder={"details firstname "}
                  />
                </div>
              </div>

              <div className="mb10">
                <DefaultEditor
                  className="form-control"
                  placeholder="News Details"
                  value={content}
                  onChange={onHtmlChange}
                />
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Add Article "}
              </Button>
            </div>
          </Card>
        </div>
      </section>
      <CustomModal data={modal} />
    </React.Fragment>
  );
};

export default ArticlesNew;
