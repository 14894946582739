import React from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Four0Four from "./Four0Four/Four0Four";
import OauthPage from "./Oauth/Oauth";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyRegistration from "./VerifyRegistration/VerifyRegistration";
import FAQ from "./FAQ/FAQ";
import Privacy from "./Privacy/Privacy";
import Terms from "./Terms/Terms";
import About from "./About/About";
import HeaderTop from "../Header/HeaderTop";
import "./Public.css";
import { decodeHtml } from "../../services/processHtml";
import { Skeleton } from "@mui/material";
import PlaceHolder from "../templates/PlaceHolder";
import DatePipe from "../../pipes/DatePipe";

const PageHeader = (props: any) => {
  const { page, loading } = props;
  return (
    <div className="page-top-area">
      <div className="container">
        <div className="page-top-desc-area">
          <div className="page-top-content">
            <h1>
              {loading ? (
                <PlaceHolder type="sentence" height={20} />
              ) : (
                page.title
              )}
            </h1>
            {!loading ? (
              <div className="slider-discription">
                {page?.description && (
                  <p
                    className="pxy0"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(page?.description),
                    }}
                  ></p>
                )}
                {page?.date && (
                  <p className="pxy0">
                    <DatePipe value={page?.date * 1000} />
                  </p>
                )}
              </div>
            ) : (
              <PlaceHolder type="sentence" height={20} card_width={30} />
            )}
            {!page?.date ||
              (!page?.description && <div className="py0">&nbsp;</div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
