import React from "react";
import RegisterForm from "./RegisterForm";
import SeoModule from "../../../services/SeoModule";
import RegisterModule from "./RegisterModule";

const Register = () => {
  return (
    <React.Fragment>
      <SeoModule
        title="Urum - Register"
        description="Register Page"
        name="Urum"
        type="Crypto Investment"
      />
      <section className="page-main">
        <div className="flex flex-col align-items-center">
          <div className="auth-logo-space">
            <a className="logo" href="/">
              <img src={`/images/logo.png`} alt="Urum Logo" />
            </a>
          </div>
        </div>

        <div className="flex flex-col flex-column justify-content-center align-items-center py20 px10">
          <div className="login-pane">
            <RegisterModule />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Register;
