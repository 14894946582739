import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import EditGalleryItem from "../../templates/EditGalleryItem";
import ConfirmModal from "../../templates/ConfirmModal";

const GalleryPopulate = (props: any) => {
  console.log("New GAllery details Renders");
  let val = props.data;
  let navigate = useNavigate();
  let params: any = useParams();
  const [gallery, setGallery] = React.useState<any>({ photos: [] });
  const [isParam, setParam] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [gallery_loaded, setGalLoaded] = React.useState<boolean>(false);
  const [staffs_fetched, setStaffsFetched] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [caption, setCaption] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [id, setId] = React.useState<any>(false);
  const [banner_id, setBannerId] = React.useState<any>(false);
  const [loading_text, setText] = React.useState<string>("Save Now");
  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [meta, setMeta] = React.useState<any>(new FormData());
  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.galleryId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchGallery(params.galleryId);
    }
  }, []);

  const fetchGallery = (id: any) => {
    console.log(id);
    setLoading(true);
    setLoaded(false);
    HttpService.getGalleryDetails(id)
      .then(
        (result) => {
          if (result.status === 1) {
            setGalLoaded(true);
            let pix: any[] = [];
            for (let i = 0; i < result.data.photos.length; i++) {
              let fa = result.data.photos[i];
              fa.is_file_changed = false;
              fa.new_file = false;
              fa.fresh = false;
              fa.preview_file =
                process.env.REACT_APP_PUBLIC_DOMAIN + result.data.photos[i].src;
              const formData = new FormData();
              fa.form_data = formData;
              pix.push(fa);
            }
            let newOb = { ...result, photos: pix };
            console.log(newOb);
            setGallery(newOb);
            if (!caption) {
              setDescription(result.data.description);
              setCaption(result.data.caption);
              setId(result.data.id);
              setBannerId(result.data.photos[0].id);
              setPreview(
                process.env.REACT_APP_PUBLIC_DOMAIN + result.data.photos[0].src
              );
            }
            setLoaded(true);
            console.log("gallery:::", gallery);
          }
        },
        (error) => {
          setGallery({ photos: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      if (name === "title") {
        setCaption(value);
      }
    },
    [gallery]
  );

  const onHtmlChange = (e: any) => {
    setDescription(e.target.value);
    console.log(description);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let formData = new FormData();
    for (let i = 0; i < gallery.photos.length; i++) {
      if (gallery.photos[i]["new_file"]) {
        formData.append("files[]", gallery.photos[i]["form_data"]);
        formData.append("ids[]", gallery.photos[i]["id"]);
        formData.append("gallery_id", gallery.id);
        console.log(gallery.photos[i]["form_data"]);
      }
    }
    formData.append("mode", "populate");
    formData.append("id", id);
    console.log(formData);
    setLoading(true);
    setLoaded(false);
    HttpService.populateGallery(formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              //setToast({ ...toast, onopen: false });
              //navigate(`/admin/gallery/p/${galleryid}`);
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const handleFileChange = (e: any, index: number) => {
    console.log("changed::: ", e, index);
    let glx = { ...gallery };
    let fname = e.target.name;
    let flx = e.target.files[0];
    flx.item_id = glx["photos"][index]["id"];
    flx.gallery_id = gallery.id;
    glx["photos"][index]["form_data"] = flx;
    glx["photos"][index]["new_file"] = true;
    glx["photos"][index].preview_file = URL.createObjectURL(flx); // Would see a path?
    setGallery({ ...glx });
    //console.log(glx);
  };

  const addMore = () => {
    let glx = { ...gallery };
    const formData = new FormData();
    glx["photos"].push({
      id: 0,
      preview_image: "",
      new_file: false,
      is_file_changed: false,
      form_data: formData,
      fresh: true,
    });
    setGallery({ ...glx });
  };
  const closeEdit = () => {
    setEdit({ ...edit, onopen: false });
  };
  const [edit, setEdit] = React.useState<any>({
    modal: { onopen: false, onclose: closeEdit, title: "Edit gallery Item" },
  });

  const editItem = (item: any) => {
    let glx = { ...gallery };
    setEdit({
      ...edit,
      modal: { ...edit.modal, onopen: true },
      ...item,
      gallery_id: id,
    });
  };

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete gallery item",
  });

  const lauchDelItem = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      message: "Are you sure you want to remove this item?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_gallery_item,
    });
  };

  const delete_gallery_item = (item: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.removeGalleryItem(item.id)
      .then(
        (resp) => {
          if (resp.status === 1) {
            setToast({ ...toast, onopen: true, message: resp.message });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setToast({ ...toast, onopen: false, onclose: onToastClose });
          }, 3000);
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: "Error" });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  return (
    <>
      <div
        className="page-top-intro flex px10 py10 align-items-center 
      justify-caption-center"
      >
        <h3 className="py0 my0 px0 mx0">
          <i className="fas fa-gallery"></i> Edit Gallery Item
        </h3>
        <div className="spacer"></div>
        <div></div>
      </div>
      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Link to={`/admin/gallery`}>Gallery</Link>
        <Link to={`/admin/gallery/p/${id}`}>{caption}</Link>
      </Breadcrumbs>
      <div className="px10 pb10">
        {gallery_loaded && (
          <Card sx={{ p: "20px", m: "0" }}>
            <div className="gallery-grid z-high">
              {gallery?.photos.map((item: any, index: number) => (
                <div className="gal-pop" key={index}>
                  <span className="qedit">
                    <a onClick={() => editItem(item)}>
                      <i className="fas fa-edit"></i>
                    </a>
                    <a onClick={() => lauchDelItem(item)}>
                      <i className="fas fa-trash"></i>
                    </a>
                  </span>
                  <img src={item.preview_file} alt={`Pic ${index}`} />
                  <div className="pic-sel">
                    <input
                      type="file"
                      name={`pic_num[${index}]`}
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </div>
                </div>
              ))}

              <div className="gal-pop">
                <Button size="large" onClick={addMore}>
                  ADD MORE
                </Button>
              </div>
            </div>
            {/* 
          {gallery.photos.map((item: any, i: number) => (
            <div className="itemizer" key={i}>
              <div className="banner-section">
                {preview_image && (
                  <div className="image_preview">
                    <img className="" src={preview_image} alt="preview Image" />
                  </div>
                )}
                <div className={loading ? " input iconed " : " input "}>
                  <label>Change Banner Image</label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          ))}*/}
            <div className="pxy10">
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Save "}
              </Button>
            </div>
          </Card>
        )}
      </div>
      <EditGalleryItem data={edit} />
      <CustomModal data={toast} />
      <ConfirmModal data={del_modal} loading={loading} />
    </>
  );
};

export default GalleryPopulate;
