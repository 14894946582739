import React, { createContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { DefaultEditor } from "react-simple-wysiwyg";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import * as processHtml from "../../../services/processHtml";
import Edit from "@mui/icons-material/Edit";
import HttpService from "../../../services/HttpService";
import PlaceHolder from "../../templates/PlaceHolder";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import HtmlModal from "../../templates/HtmlModal";
import CustomModal from "../../templates/CustomModal";
import Delete from "@mui/icons-material/Delete";
import ConfirmModal from "../../templates/ConfirmModal";
import HomeMain from "../../Public/Home/HomeMain";
import Cards from "../../Public/Home/Cards";
import Thinking from "../../Public/Home/Thinking";
import PgIntro from "../../Public/Home/PgIntro";
import Profiles from "../../Public/Home/Profiles";
import LeaveMessage from "../../Public/Home/LeaveMessage";
import HomeNews from "../../Public/Home/HomeNews";
import Faq from "../Faq/Faq";
import FAQ from "../../Public/FAQ/FAQ";
import Footer from "../../Footer/Footer";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Slide from "@mui/material/Slide";
import { HomeContext } from "../../../services/HomeContext";
import { Breadcrumbs, Grid } from "@mui/material";

export default function Index() {
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <section className="pxy20">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Link
                    className="grid-card bg-grax"
                    to="/admin/homepage/intro"
                  >
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-home"></i>
                      </div>
                      <div className="pane-title mt20">Home Page Intro</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link
                    className="grid-card bg-grax"
                    to="/admin/homepage/mission"
                  >
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-comment"></i>
                      </div>
                      <div className="pane-title mt20">Mission Cards</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/homepage/igwe">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="pane-title mt20">Igwe Section</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/homepage/pg">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-user-tie"></i>
                      </div>
                      <div className="pane-title mt20">PG Section</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link
                    className="grid-card bg-grax"
                    to="/admin/homepage/contact"
                  >
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="pane-title mt20">Contact Section</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link
                    className="grid-card bg-grax"
                    to="/admin/homepage/footer"
                  >
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-flag"></i>
                      </div>
                      <div className="pane-title mt20">Footer Message</div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </section>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
}
