import React from "react";
import { Link } from "react-router-dom";
import { HomeContext } from "../../../services/HomeContext";
import * as processHtml from "../../../services/processHtml";

const HomeMain = () => {
  const [home, setHome] = React.useContext(HomeContext);
  const { decodeHtml } = processHtml;
  return (
    <React.Fragment>
      <div className="slider-area ">
        <div className="container">
          <div className="flex flex-row-resp relative">
            <div className="home-desc-area">
              <div className="slider-content">
                <div className="slider-title">
                  {home?.home_page_intro ? (
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(home?.home_page_intro?.header),
                      }}
                    ></h1>
                  ) : (
                    <h1>Welcome to Urum</h1>
                  )}
                </div>
                <div className="slider-discription">
                  {home?.home_page_intro ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(home?.home_page_intro?.description),
                      }}
                    ></p>
                  ) : (
                    <p>
                      Building with our brothers & residents is something we
                      keep close to our heart. Getting to know their personal
                      stories and celebrating them together is what makes our
                      town truly a great assemble of brotherliness and progress
                    </p>
                  )}
                  <div className="hcta">
                    <Link className="w" to={"/about"}>
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="side-area world-map"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeMain;
