import { Link } from "react-router-dom";
import "./Header.css";
const DesktopHeaderNav = (props: any) => {
  const { isLogged } = props;
  return (
    <div className="desktop-header-bar">
      <ul className="header-nav">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="icon">
          <Link to="/about">About Us</Link>
          <ul>
            <li>
              <Link to="/about">About Urum</Link>
            </li>
           {/*  <li>
              <Link to="/executives">Executives</Link>
            </li> */}
            <li>
              <Link to="/past-leaders">Past Leaders</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
        </li>
        <li className="icon">
          <a>Heritage</a>
          <ul>
            <li>
              <Link to="/villages">Villages</Link>
            </li>
            <li>
              <Link to="/festivals">Festivals</Link>
            </li>
          </ul>
        </li>
        {/* <li>
          <Link to="/articles">Articles</Link>
        </li> */}
        <li>
          <Link to="/gallery">Gallery</Link>
        </li>
      </ul>

      <div className="header-post">
        <Link className="phone-spot" to="/contact-us">
          <span className="phone-text">+234 701 366 0304</span>
          <em>Call Us</em>
        </Link>
        <span className="icon-spot">
          <img src="/images/icon.png" alt="" />
        </span>
      </div>
    </div>
  );
};

export default DesktopHeaderNav;
