import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import SeoModule from "../../../services/SeoModule";
import { LinearProgress } from "@mui/material";

const ResetPassword = () => {
  const params = useParams();
  console.log(params);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [response_text, setResponseText] = React.useState<string>("");
  const [response_class, setResponseClass] = React.useState<string>("");
  const [code_confirmed, setConfirmed] = React.useState<boolean>(false);
  const [confirmed_message, setConfirmedMessage] = React.useState<string>("");
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (params.id && params.token) {
      confirmCode(params.id, params.token);
    }
  }, [params]);

  const [form, setForm] = React.useState<any>({});
  const handleInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setForm({ ...form, [name]: value });
  };

  const confirmCode = (id: any, token: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.confirmPasswordReset({
      id: id,
      token: token,
    })
      .then(
        (response) => {
          let rsp = response;
          console.log("conf code ::::", rsp);
          const severity =
            rsp.status === 1
              ? "color-success"
              : rsp.status === 0
              ? "color-error"
              : "color-info";

          setResponseClass(severity);
          if (rsp.status === 1) {
            console.log("conf code :::: is confirmed");
            setConfirmed(true);
            setConfirmedMessage(`Your password reset request verified, please change your password below
               `);
            setForm({ ...form, user: rsp.user });
            console.log(code_confirmed);
          } else {
            setConfirmedMessage(`<h3>We cannot verify your password reset request.
                  <br />
                  Please reload the page or initiate a fresh password reset
                  request.</h3>
               `);
          }
        }, //resPonse ends//
        (error) => {
          setModal({
            ...modal_data,
            onopen: true,
            message: error.message,
            onclose: modalClose,
          });
          setConfirmed(false);
        } //error ends//
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const sendReset = () => {
    console.log(form);
    if (!form.new_password || form.re_password === "") {
      return alert("Please supply your new password");
    } else if (form.new_password !== form.re_password) {
      return alert("Passwords doesn't match");
    }
    const data = { ...form, mode: "reset", old_password: "0123456" };
    console.log("Form::", data);
    setConfirmedMessage("");
    setLoading(true);
    setLoaded(false);
    HttpService.changePassword(data)
      .then(
        (response) => {
          let rsp = response;
          modalClose();
          const severity =
            rsp.status === 1 ? "success" : rsp.status === 0 ? "error" : "info";

          setModal({
            ...modal_data,
            onopen: true,
            onclose: modalClose,
            severity: severity,
            message: rsp.message,
          });
          console.log("::::", rsp);
        }, //resPonse ends//
        (error) => {
          setModal({
            ...modal_data,
            onopen: true,
            message: error.message,
            severity: "error",
            onclose: modalClose,
          });
        } //error ends//
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return (
    <React.Fragment>
      <SeoModule
        title="Urum- Reset Password"
        description="Reset Password"
        name="Urum"
        type="Crypto Investment"
      />
      <section className="page-main">
        {/* 
        <section className="page-top bg-grax">
          <div className="page-info">
            <h2>Reset Password</h2>
          </div>
        </section> */}

        <div className="flex flex-col align-items-center">
          <div className="auth-logo-space">
            <a className="logo" href="/">
              <img src={`/images/logo.png`} alt="Urum Logo" />
            </a>
          </div>
        </div>

        <div className="flex flex-col flex-column justify-content-center align-items-center py20">
          <div className="page-info text-center">
            <h2>Reset Password</h2>
          </div>

          <div className="login-pane">
            <div
              className={`py10 mb20 ${response_class} text-center`}
              dangerouslySetInnerHTML={{
                __html: confirmed_message,
              }}
            ></div>
            {loading && <LinearProgress />}
            {code_confirmed && (
              <>
                <div className="input iconed mt20">
                  <label>Password</label>
                  <input
                    type="password"
                    name="new_password"
                    disabled={loading}
                    className="input-form-control"
                    placeholder="Password"
                    onChange={handleInput}
                  />
                  <span className="input-icon">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>

                <div className="input iconed">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    name="re_password"
                    disabled={loading}
                    className="input-form-control"
                    placeholder="Confirm Password"
                    onChange={handleInput}
                  />
                  <span className="input-icon">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>
                <div className="flex flex-row align-items-center">
                  <Button
                    onClick={sendReset}
                    size="large"
                    disabled={loading}
                    variant="contained"
                    type="submit"
                    fullWidth
                  >
                    {loading ? "Working..." : "Change Password"}
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="py20 text-center">
            Don't have an account? <Link to="/register">Register here</Link>
          </div>
        </div>

        <CustomModal data={modal_data} />
      </section>
    </React.Fragment>
  );
};

export default ResetPassword;
