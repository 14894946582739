import React from "react";
import { Link, useParams } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";
import { LinearProgress } from "@mui/material";

const VerifyRegistration = () => {
  const params = useParams();
  console.log(params);
  const { decodeHtml } = processHtml;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [response_text, setResponseText] = React.useState<string>("");
  const [code_confirmed, setConfirmed] = React.useState<boolean>(false);
  const [class_state, setClass] = React.useState<string>("");
  const [confirmed_message, setConfirmedMessage] = React.useState<string>("");
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id && params.token) {
      confirmCode(params.id, params.token);
    }
  }, [params]);

  const [form, setForm] = React.useState<any>({});
  const handleInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setForm({ ...form, [name]: value });
  };

  const confirmCode = (id: any, token: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.verifyRegistration({
      id: id,
      token: token,
    })
      .then(
        (response) => {
          let rsp = response;
          console.log("conf code ::::", rsp);
          setConfirmedMessage(rsp.message);
          const ist =
            rsp.status === 1 ? "badger badge-success" : "badger badge-error";
          setClass(ist);
          if (rsp.status === 1) {
            console.log("conf code :::: is confirmed");
            setConfirmed(true);
            setForm({ ...form, user: rsp.user.email });
            console.log(code_confirmed);
            AuthService.logout();
          }
        }, //resPonse ends//
        (error) => {
          setModal({
            ...modal_data,
            onopen: true,
            message: error.message,
            onclose: modalClose,
          });
          setConfirmed(false);
        } //error ends//
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return (
    <React.Fragment>
      <SeoModule
        title="Urum- Verify Registration"
        description="Verify Registration"
        name="Urum"
        type="Crypto Investment"
      />
      <section className="page-main">
        <div className="flex flex-col align-items-center">
          <div className="auth-logo-space">
            <a className="logo" href="/">
              <img src={`/images/logo.png`} alt="Urum Logo" />
            </a>
          </div>
        </div>

        <div className="flex flex-col flex-column justify-content-center align-items-center py20 px10">
          <div>
            <h2>Verify Registration</h2>
          </div>
          <div className="login-pane py20">
            {loaded && (
              <div className={class_state}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(confirmed_message),
                  }}
                ></div>
                <div>
                  {" "}
                  <Link to="/login"> Login</Link>{" "}
                </div>
              </div>
            )}
            {loading && (
              <div className="py20 text-center error  txt-lg">
                <LinearProgress />
                <h3 className="my20">Finding account...</h3>
              </div>
            )}
          </div>
          <div className="py20 text-center">
            Don't have an account? <Link to="/register">Register here</Link>
          </div>
        </div>

        <CustomModal data={modal_data} />
      </section>
    </React.Fragment>
  );
};

export default VerifyRegistration;
