import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ProfileTemplate from "../../templates/ProfileTemplate";
import ChangePasswordTemplate from "../../templates/ChangePasswordTemplate";
import AuthService from "../../../services/AuthService";

const ProfilePage = () => {
  const [path, setPath] = React.useState<string>("profile");
  const [profile_set, setProfileSet] = React.useState<boolean>(false);
  const user = AuthService.getCurrentUser();
  React.useEffect(() => {
    if (user) {
      setProfileSet(true);
    }
  }, []);
  const navas = [
    { path: "profile", title: "Profile" },
    { path: "change_password", title: "Change Password" },
    { path: "address", title: "Wallet Address" },
  ];
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container pxy20-resp">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bga">
              <div className="flex flex-row-resp px20">
                <div className="inline-block py20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/account/dashboard">Dashboard</Link>
                    <span>Profile</span>
                  </Breadcrumbs>
                  <h2 className="mt20">Profile</h2>
                </div>
              </div>
            </div>

            <ul className="flat-nav border-bottom">
              {navas.map((item: any, index: number) => (
                <li key={item.path}>
                  <a
                    href={"#"}
                    className={path === item.path ? "active" : ""}
                    onClick={() => setPath(item.path)}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
            <section className="pxy20">
              {profile_set && path === "profile" && (
                <ProfileTemplate user={user} fetched={profile_set} />
              )}
              {profile_set && path === "change_password" && (
                <ChangePasswordTemplate user={user} fetched={profile_set} />
              )}
              {profile_set && path === "address" && <>===</>}
            </section>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ProfilePage;
