import React from "react";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import GalleryListTemplate from "../../templates/GalleryListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import PageHeader from "../PageHeader";
import SeoModule from "../../../services/SeoModule";
import Header from "../../Header/Header";
import { Icon } from "@mui/material";

const GalleryList = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [gallery, setGallery] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [result_finished, setResultFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    listGallery(offset);
  }, []);

  const listGallery = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.listGallery({ offset: offset, limit: 12, is_laravel: true })
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            if (result.data.length === 0) {
              setResultFinished(true);
            } else {
              let newRes = [...gallery, ...result.data];
              setGallery(newRes);
            }
          } else {
            setGallery([]);
          }
        },
        (error) => {
          setGallery([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listGallery(newOffset);
  };
  return (
    <React.Fragment>
      <SeoModule
        title="Urum - Gallery"
        description="Fintech: banking, crypto, investments"
        name="Urum"
        page_url={process.env.REACT_APP_PUBLIC_DOMAIN + "/gallery"}
        page_image={process.env.REACT_APP_PUBLIC_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />

      <div className="main-bg-grad page-top-cover">
        <div className="home-overlay"></div>
        <Header />
        <PageHeader
          page={{
            title: "Gallery",
            description: "&nbsp;",
          }}
        />
      </div>

      <section className="container py30">
        {loaded && (
          <GalleryListTemplate
            gallery_list={gallery}
            loaded={loaded}
            loading={loading}
          />
        )}
        {loading && (
          <PlaceHolder
            type="block_list"
            card_height={250}
            card_border_radius={10}
          />
        )}
        {loaded && (
          <div className="flex justify-content-center align-items-center py20">
            <Button
              disabled={loading || result_finished}
              size="large"
              variant="contained"
              onClick={loadMore}
            >
              {loading ? "Loading..." : "Load More"}{" "}
              <Icon>
                {loading ? (
                  <i className="fas fa-spin fa-circle-notch"></i>
                ) : (
                  <ArrowDownwardOutlined />
                )}
              </Icon>
            </Button>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default GalleryList;
