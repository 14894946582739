import React from "react";
import { Link } from "react-router-dom";
import * as processHtml from "../../services/processHtml";
import "./Footer.css";

//
const Footer = () => {
  const [home, setHome] = React.useState<any>(null);
  const { decodeHtml } = processHtml;
  return (
    <React.Fragment>
      <footer className="footer-area">
        <div className="container">
          <div className="flex flex-row-resp">
            <div className="col-info footer-description">
              <div className="footer-logo">
                <a href="#">
                  <img src="images/logo.png" alt="" />
                </a>
              </div>
              <div className="footer-discription">
                {home ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(home?.footer.text),
                    }}
                  ></p>
                ) : (
                  <p>
                    Once you experience our culture, you’re sure to understand
                    what makes us so special. It’s a great way for you to make
                    use of all the wonderful benefits of being a part of our
                    communities.
                  </p>
                )}
              </div>
            </div>

            <div className="col-info spacer">
              <div className="footer-title">
                <h3>Community</h3>
              </div>
              <ul className="footer-nav">
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/history">History</Link>
                </li>
                {/*  <li>
                  <Link to="/executives">Executives</Link>
                </li> */}
                <li>
                  <a href="/past-leaders">Past Leaders</a>
                </li>
                <li>
                  <Link to="/villages">Villages</Link>
                </li>
              </ul>
            </div>
            {/**col ends */}
            <div className="col-info spacer">
              <div className="footer-title">
                <h3>Resources</h3>
              </div>
              <ul className="footer-nav">
                {/*   <li>
                  <Link to="/articles">Articles</Link>
                </li> */}
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/festivals">Festivals</Link>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
            {/**col ends */}
            <div className="col-info spacer">
              <div className="footer-title">
                <h3>Let's&nbsp;hear&nbsp;you</h3>
              </div>
              <ul className="footer-nav">
                Please
                <Link to={`/contact-us`}> Contact us</Link> for more info on
                Urum Town
              </ul>
            </div>
            {/**col ends */}
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default React.memo(Footer);
