import React from "react";
import { Link } from "react-router-dom";
import * as processHtml from "../../services/processHtml";
import { Collapse, Grid, IconButton, Tooltip } from "@mui/material";
import DatePipe from "../../pipes/DatePipe";
const NewsListTemplate = (props: any) => {
  const { news, loaded, loading } = props;
  const base_url = props.base_url ? "/" + props.base_url : "";
  const [list, setList] = React.useState<any[]>([]);
  const { decodeHtml, truncateWord } = processHtml;

  React.useEffect(() => {
    if (news) {
      setList(news);
    }
  }, [news]);

  const togView = (index: number) => {
    console.log(index);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };
  return (
    <React.Fragment>
      <Grid container spacing={3.5}>
        {list.map((item: any, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={item.url}>
            <div className="news-card">
              <Link
                className="thumb-pane"
                to={`${base_url}/articles/${item.url}`}
              >
                <div className="news-thumb">
                  <img
                    src={process.env.REACT_APP_PUBLIC_DOMAIN + item.thumb}
                    alt="news banner"
                  />
                </div>

                <div className="category-span">
                  <Link to={`/category/`}>{item.create_date}</Link>
                </div>
              </Link>
              {item.is_thumb && <></>}

              <div className="news-content-area">
                <div className="flex flex-row align-items-center">
                  <div className="date-space spacer">
                    <i className="fas fa-clock"></i>{" "}
                    <DatePipe value={item.create_date * 1000} />
                  </div>

                  <span className="texr">-</span>
                </div>

                <h3>
                  <Link to={`${base_url}/articles/${item.url}`}>
                    {truncateWord(item.title, 90)}
                  </Link>
                </h3>

                <div
                  className="news-summary"
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(truncateWord(item?.content, 120)),
                  }}
                ></div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(NewsListTemplate);
