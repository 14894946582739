import React from "react";
import { Routes, Route } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import NewsDetail from "./NewsDetail";
import NewsList from "./NewsList";

const Articles = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<NewsList />} />
        <Route path="/:url" element={<NewsDetail />} />
      </Routes>
    </React.Fragment>
  );
};

export default Articles;
