import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import PlaceHolder from "../../templates/PlaceHolder";
import useFetchNewsCategories from "../../../hooks/useFetchNewsCategories";

import CustomModal from "../../templates/CustomModal";

const ArticlesEdit = () => {
  let navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const isParam = params.aUrl ? true : false;
  let [news, setNews] = React.useState<any>({});
  const { news_categories } = useFetchNewsCategories();
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit News",
  });

  const [title, setTitle] = React.useState<any>("");
  const [cat_id, setCatId] = React.useState<any>("");
  const [content, setContent] = React.useState<any>("");
  let [id, setId] = React.useState<any>(false);

  React.useEffect(() => {
    console.log(params);
    if (isParam) {
      getNews(params.aUrl);
    }
  }, [params]);

  const getNews = (url: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.getNewsDetails(url)
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setNews(result.data);
            setTitle(result.data.title);
            setContent(result.data.content);
            setCatId(result.data.cat_id);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_PUBLIC_DOMAIN + result.data.url);
          } else {
            setNews({});
          }
        },
        (error) => {
          setNews({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("title", news?.title);
    formData.append("cat_id", news?.cat_id);
    formData.append("id", id);
    formData.append("banner", file);
    formData.append("content", content);
    formData.append("is_new_file", new_file);
    HttpService.editNews(
      formData /* {
      title: title,
      content: content,
      cat_id: cat_id,
      id: id,
      mode: "edit-news",
    } */
    )
      .then((resp) => {
        console.log(resp);
        if (resp.status === 1) {
          setModal({ ...modal, onopen: true, message: resp.message });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.taget);
      setContent(e.target.value);
    },
    [content]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/articles">Articles</Link>
                    <span>{news.title}</span>
                  </Breadcrumbs>
                  <h2>Edit Article</h2>
                </div>
              </div>
            </div>

            {loading && <PlaceHolder type="edit_page" />}

            {loaded && (
              <div className="py30 px20">
                <div className={loading ? " input iconed " : " input "}>
                  <label>Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    disabled={loading}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={"Title "}
                  />
                </div>

                <div className={loading ? " input iconed " : " input "}>
                  <label>Category</label>
                  <select
                    className="input-form-control"
                    onChange={(e) => setCatId(e.target.value)}
                    name="cat_id"
                    defaultValue={cat_id}
                  >
                    <option value="0">Main Category</option>
                    {news_categories.map((itm: any, ind: number) => (
                      <option value={itm.id}>{itm.title}</option>
                    ))}
                  </select>
                </div>

                <div className="banner-section">
                  {preview_image && (
                    <div className="image_preview">
                      <img
                        className=""
                        src={preview_image}
                        alt="preview Image"
                      />
                    </div>
                  )}
                  <div
                    className={
                      loading ? " input iconed mt20 pt10" : " input mt20 pt10"
                    }
                  >
                    <label>Attach Page Banner</label>
                    <input
                      type="file"
                      className="form-control"
                      name="file"
                      onChange={handleFileChange}
                      placeholder={"details firstname "}
                    />
                  </div>
                </div>

                <div className="mb10">
                  <DefaultEditor
                    className="form-control"
                    value={content}
                    disabled={loading}
                    placeholder="Page Details"
                    onChange={onHtmlChange}
                  />
                </div>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? "Working..." : " Edit Article "}
                </Button>
              </div>
            )}
          </Card>
        </div>
      </section>
      <CustomModal data={modal} />
    </React.Fragment>
  );
};

export default ArticlesEdit;
