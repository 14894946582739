import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import GalleryEdit from "./GalleryEdit";
import GalleryList from "./GalleryList";
import GalleryNew from "./GalleryNew";
import GalleryPopulate from "./GalleryPopulate";
import GalleryDetails from "./GalleryDetails";

const Gallery = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/admin/properties/a/new`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<GalleryList />} />
        <Route path="/" element={<GalleryList />} />
        <Route path="/new" element={<GalleryNew />} />
        <Route path="/p/:galleryId" element={<GalleryDetails />} />
        <Route path="/e/:galleryId" element={<GalleryEdit />} />
        <Route path="/px/:galleryId" element={<GalleryPopulate />} />
      </Routes>
    </React.Fragment>
  );
};

export default Gallery;
