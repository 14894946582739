import React from "react";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const MobileHeaderNav = (props: any) => {
  const { isLogged, isTogged, setIsTogged } = props;
  const [togged, setTogged] = React.useState<any[]>([false, false]);
  const setTog = (index: number) => {
    const nr = [...togged];
    nr[index] = !nr[index];
    setTogged(nr);
  };
  return (
    <ul className="mobile-header-nav">
      <li>
        <Link onClick={() => setIsTogged(!isTogged)} to="/">
          Home
        </Link>
      </li>
      <li className="icon">
        <div className="list-fig">
          <Link className="spacer" to="/about">
            About Us
          </Link>
          <span className="dropper">
            <a onClick={() => setTog(1)}>
              <i
                className={`fas ${
                  togged[1] ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </a>
          </span>
        </div>
        <Collapse in={togged[1]}>
          <ul>
            <li>
              <Link to="/about">About Urum</Link>
            </li>
            {/*   <li>
              <Link to="/executives">Executives</Link>
            </li> */}
            <li>
              <a href="/past-leaders">Past Leaders</a>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
        </Collapse>
      </li>
      <li className="icon">
        <div className="list-fig">
          <a>Heritage Heritage</a>
          <span className="dropper">
            <a onClick={() => setTog(2)}>
              <i
                className={`fas ${
                  togged[2] ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </a>
          </span>
        </div>
        <Collapse in={togged[2]}>
          <ul>
            <li>
              <Link to="/villages">Villages</Link>
            </li>
            <li>
              <Link to="/festivals">Festivals</Link>
            </li>
          </ul>
        </Collapse>
      </li>
      {/*    <li>
        <Link to="/articles">Articles</Link>
      </li> */}
      <li>
        <Link to="/gallery">Gallery</Link>
      </li>
    </ul>
  );
};

export default MobileHeaderNav;
