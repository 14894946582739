import React, { createContext } from "react";
import { Routes, Route } from "react-router-dom";
import HomepageIntro from "./HomepageIntro";
import Index from "./Index";
import HttpService from "../../../services/HttpService";
import { HomeProvider } from "../../../services/HomeContext";
import Footer from "./Footer";
import IgweSection from "./IgweSection";
import PgSection from "./PgSection";
import ContactSection from "./ContactSection";
import MissionCards from "./MissionCards";

const Homepage = (props: any) => {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [home, setHome] = React.useState<any>(null);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomeProvider>
      <Routes>
        <Route path="" element={<Index />} />
        <Route path="/intro" element={<HomepageIntro />} />
        <Route path="/mission" element={<MissionCards />} />

        <Route path="/igwe" element={<IgweSection />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/pg" element={<PgSection />} />
        <Route path="/footer" element={<Footer />} />
      </Routes>
    </HomeProvider>
  );
};

export default Homepage;
