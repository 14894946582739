import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import * as processHtml from "../../services/processHtml";

const ProfileCard = (props: any) => {
  const { profiles, loading, loaded, max_grid, base_url } = props;
  const [list, setList] = React.useState<any[]>(profiles);
  const { decodeHtml } = processHtml;
  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };

  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <React.Fragment>
      <div className="">
        {loaded && (
          <>
            <Grid container spacing={3}>
              {list.map((item: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={max_grid ? max_grid : 3}
                  key={item.id}
                >
                  <Link
                    to={`/${base_url ? base_url : "executives"}/${item.id}`}
                    className="profile-card-container"
                  >
                    <div className="profile-card-content">
                      <div className="profile-card-thumb-image-holder">
                        <img
                          src={
                            process.env.REACT_APP_PUBLIC_DOMAIN + item.picture
                          }
                          alt={item.name}
                        />
                      </div>
                      <div className="meta-pane">
                        <h3>{item.name}</h3>
                        <div className="desc-text">
                          {item.designation}{" "}
                          {item.is_current === 0 && (
                            <span className="italic">({item.tenure})</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(ProfileCard);
