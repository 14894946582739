import { Card, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
const Dashboard = () => {
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py0">
          <Card sx={{ borderRadius: "0", backgroundColor: "transparent" }}>
            <section className="pxy20">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/homepage">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-home"></i>
                      </div>
                      <div className="pane-title mt20">Home Page</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/articles">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-file"></i>
                      </div>
                      <div className="pane-title mt20">Articles</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/gallery">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-image"></i>
                      </div>
                      <div className="pane-title mt20">Gallery</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/faq">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-question-circle"></i>
                      </div>
                      <div className="pane-title mt20">FAQ</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/pages">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-file"></i>
                      </div>
                      <div className="pane-title mt20">Pages</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/executives">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-user-circle-o"></i>
                      </div>
                      <div className="pane-title mt20">Executives</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bg-grax" to="/admin/users">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="pane-title mt20">Users</div>
                    </div>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Link className="grid-card bga" to="/admin/site-settings">
                    <div className="pxy30">
                      <div className="icon-pane">
                        <i className="fas fa-cogs"></i>
                      </div>
                      <div className="pane-title mt20">Site Settings</div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </section>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
